.container {
  display: flex;
  flex-direction: column;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 85%;
  height: 30vw;
  @media (min-width: 2400px) {
    width: 70%;
    height: 25vw;
    background-size: 80%;
  }
}

.mainContainer,
.noArrowsContainer {
  display: flex;
  height: 30vh;
  width: 80vw;
  align-items: center;
  justify-content: space-between;
  @media (min-width: 2400px) {
    width: 50vw;
  }
}

.noArrowsContainer {
  margin-top: 5vh;
  justify-content: center;
  @media (max-width: 1500px) {
    margin-top: 8vh;
  }
}

.imageContainer {
  margin: 0 2vw;
  height: min(70px, 5vh);
  width: min(70px, 2vw);
  user-select: none;
}

.imageContainer img {
  height: 100%;
  width: 100%;
}

.detailsContainer {
  margin: 0 5vw;
  height: 100%;
  width: 50vw;
}

.disabled {
  display: none;
}

.reviewAuthor {
  font-size: clamp(18px, 1.75vw, 3rem);
  font-family: 'Seconda Round Bold';
  color: #8f6f6f;
  text-shadow: 0px 4px 4px rgb(0, 0, 0, 0.25);
  margin-bottom: 25px;
}
.reviewText {
  font-size: clamp(15px, 1.1vw, 1.3rem);
  font-family: 'Seconda Round Bold';
  color: #8f6f6f;
  text-shadow: 0px 4px 4px rgb(0, 0, 0, 0.25);
}
