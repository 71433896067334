@import "../../../core/theme/themes.scss";
@import "../../../core/theme/colors.scss";

.teamMembersContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.teamMembersComponentContainer {
  width: 50%;
}
.teamMembersComponent {
  display: flex;
  width: 100vw;
  height: 60vw;
  margin-left: -22px;
}
.teamMembersDescriptionContainer {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  width: 50%;
}
.progressBarsContainers {
  display: flex;
  flex-direction: row;
}
.progressBarsContainer {
  width: 24vw;
  height: 24vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.progressBar {
  display: block;
  font-family: "Seconda Round Black";
  font-size: 1.2vw;
}

.teamMembersTitle {
  font-family: "Seconda Round Bold";
  font-size: 22px;
  color: #8f6f6f;
  font-weight: bold;
  text-align: center;
}
.teamMembersDescription {
  font-family: "Seconda Round Bold";
  color: #8f6f6f;
  font-size: 3vw;
  text-align: center;
}
.separator {
  height: 10px;
}
.progressBarsContainers {
  display: flex;
  flex-direction: column;
}
.ourDedicatedTeamText {
  font-family: "Seconda Round Bold";
  color: #8f6f6f;
  font-size: 22px;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.teamMembersListContainer {
  display: flex;
  width: 100%;
  margin: auto;
}

.container {
  width: 100%;
  padding-top: 15vh;
}

.centeredCol {
  display: flex;
  justify-content: center;
  align-items: center;
}

.topContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  padding-left: 10vw;
  padding-right: 10vw;
  align-items: center;
}

.title {
  font-size: 8vw;
  color: $bazaar;
  font-family: "Seconda Round Black";
}

.description {
  color: $bazaar;
  font-size: 4vw;
  text-align: justify;
  font-family: "Seconda Round Medium";
}

.mainPhoto {
  width: 100%;
  height: fit-content;
  border-radius: 20px;
}

.bottomContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}

.secondaryTitle {
  font-size: 5vw;
  font-family: "Seconda Round Black";
  color: $opium;
}

.textHeader {
  font-size: 4vw;
  color: $opium;
  font-family: "Seconda Round Bold";
  display: list-item;
  text-align: justify;
  margin-left: 15px;
}

.text {
  font-size: 3.5vw;
  color: $opium;
  font-family: "Seconda Round Medium";
}

.textContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  align-items: center;
}
