.container {
  padding-bottom: 75px;
  width: 100%;
  height: 70vw;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.mainContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40vh;
  width: 74vw;
}

.imageContainer {
  margin: 0 2vw;
  height: 5vh;
  width: 3vw;
  user-select: none;
}

.imageContainer img {
  height: 100%;
  width: 100%;
}

.detailsContainer {
  height: 32vw;
  width: 54vw;
}

.disabled {
  display: none;
}

.reviewAuthor {
  font-size: clamp(15px, 4vw, 3rem);
  font-family: 'Seconda Round Black';
  color: #8f6f6f;
  text-shadow: 0px 4px 4px rgb(0, 0, 0, 0.25);
  margin-bottom: 1.5vh;
}

.reviewText {
  font-size: clamp(13px, 1.8vw, 1.3rem);
  font-family: 'Seconda Round Medium';
  color: #8f6f6f;
  text-shadow: 0px 4px 4px rgb(0, 0, 0, 0.25);
}
