@import '../../../core/theme/themes.scss';
@import '../../../core/theme/colors.scss';

.mainButton {
  width: 8.8vw;
  font-size: 0.2vw;
  border-radius: 100px;
  margin-top: 7px;
  margin-left: 7px;
  position: absolute;
  z-index: 0;
  min-height: 50px;
  transition: 0.4s;
  @media (min-width: 2400px) {
    height: 50px;
    width: 7.5vw;
  }
  @media (min-width: 3000px) {
    height: 50px;
    width: 6vw;
  }
}

.innerButton {
  z-index: 1;
  display: flex;
  justify-content: center;
  align-content: center;
  width: 8.8vw;
  min-height: 50px;
  transition: 0.4s;
  height: 2.6em;
  max-height: 65px;
  border-radius: 100px;
  border: 4px solid;
  color: $opium;
  cursor: pointer;
  & span {
    font-family: 'Seconda Round Bold';
    font-weight: bold;
    align-self: center;
  }
  color: gray;
  font-family: 'Seconda Round Bold';
  font-size: 0.8vw;
  @media (min-width: 2400px) {
    height: 50px;
    width: 7.5vw;
  }
  @media (min-width: 3000px) {
    height: 50px;
    width: 6vw;
    font-size: 0.6vw;
  }
}

.btnGroup {
  width: 8.8vw;
  height: 4px;
  display: flex;
  transition: 0.2s;

  &:hover {
    cursor: pointer;
    transition: 0.2s;
    .mainButton {
      transition: 0.4s;
      margin-top: 0px;
      margin-left: 0px;
    }
    .innerButton {
      transition: 0.2s;
      color: $white;
      border: none;
    }
  }
}
