.container {
  margin-top: 5vh;
  width: 70%;
}

.imagesContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  justify-items: center;
  align-items: center;
}

.lastImageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.image {
  width: 22.5vw;
  margin-top: 5.5vh;
  opacity: 0.4;
}

.nessImage {
  width: 10vw;
  position: relative;
  right: 1vw;
  opacity: 0.4;
}
.odeenImage {
  width: 25vw;
  position: relative;
  margin-top: 5.3vh;
  opacity: 0.3;
}
.izibacImage {
  width: 25vw;
  position: relative;
  margin-top: 5vh;
  opacity: 0.8;
}
.moonletImage {
  width: 25vw;
  position: relative;
  margin-top: 5vh;
  opacity: 0.7;
}
.pentalogImage {
  width: 25vw;
  position: relative;
  margin-top: 2vh;
  opacity: 0.4;
}
.nestInnImage {
  width: 22.5vw;
  position: relative;
  margin-top: 4vh;
  opacity: 0.4;
}
.nestInnImage:hover,
.nessImage:hover,
.image:hover,
.nessImage:hover,
.odeenImage:hover,
.izibacImage:hover,
.moonletImage:hover,
.pentalogImage:hover {
  opacity: 1;
}
