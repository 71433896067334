.container {
	display: flex;
	width: 100%;
	height: 330px;
	align-items: center;
	justify-content: center;
	background-size: cover;
}

.pageLogo {
	height: auto;
	width: 50%;
	padding-bottom: 10px;
	padding-top: 80px;
}
