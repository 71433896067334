.container {
  width: 70%;
  display: flex;
  align-self: center;
  margin-top: 10vh;
  flex-direction: column;
  margin-bottom: 10vh;
  @media (min-width: 2200px) {
    margin-top: 5vh;
  }
}
.image {
  width: 10vw;
  opacity: 0.4;
  height: auto;
  @media (min-width: 2200px) {
    width: 7vw;
  }
}
.firstThreePhotos {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.lastThreePhotos {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 4vh;
  margin-left: 4vw;
}
.nessImage {
  width: 6vw;
  opacity: 0.4;
  position: relative;
  left: 1.5vw;
  @media (min-width: 2200px) {
    width: 4vw;
  }
}
.odeenImage {
  width: 10vw;
  position: relative;
  right: 2vw;
  opacity: 0.3;
  @media (min-width: 2200px) {
    width: 7vw;
  }
}
.izibacImage {
  width: 10vw;
  position: relative;
  right: 1.5vw;
  opacity: 0.8;
  @media (min-width: 2200px) {
    width: 7vw;
  }
}
.moonletImage {
  width: 10vw;
  position: relative;
  right: 1vw;
  opacity: 0.7;
  @media (min-width: 2200px) {
    width: 7vw;
  }
}
.pentalogImage {
  width: 10vw;
  position: relative;
  left: 2vw;
  opacity: 0.4;
  @media (min-width: 2200px) {
    width: 7vw;
  }
}
.nestInnImage {
  width: 12vw;
  position: relative;
  right: 1.5vw;
  opacity: 0.4;
  @media (min-width: 2200px) {
    width: 7vw;
  }
}
.nestInnImage:hover,
.nessImage:hover,
.image:hover,
.nessImage:hover,
.odeenImage:hover,
.izibacImage:hover,
.moonletImage:hover,
.pentalogImage:hover {
  opacity: 1;
}
