@import "../../../core/theme/colors.scss";
@import "../../../core//theme/themes.scss";

.discordContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 200px 0 0 0;
  padding: 0 5vw;
}

.discordContainer h1 {
  font-size: clamp(15px,2vw,35px);
  color: var(--main-blue, #6aa5ac);
  font-family: "Seconda Round Medium";
}

.discordContainer p {
  font-size: clamp(15px,2vw,25px);
  font-family: "Seconda Round Medium";
  color: $opium;
}

.discordContainer img {
  width: 5vw;
  cursor: pointer;
  @media (min-width: 2200px) {
    width: 4vw;
  }
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 50px 14vw 150px 14vw;
  @media (min-width: 2200px) {
    margin: 50px 18vw 150px 18vw;
  }
}

.staffContainer {
  margin: 0 1.04vw;
  @media (max-width: 2200px) {
    font-size: 1.2vw;
  }
}

.profilesContainer {
  display: flex;
  justify-content: start;
  align-items: center;
}

.profiles {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 2vw;
}

.profiles img {
  width: 7.8125vw;
  @media (min-width: 2200px) {
    width: 5vw;
  }
}

.profiles h3 {
  color: $opium;
  font-family: "Seconda Round Black";
  font-size: clamp(20px, 1.3vw, 30px);
}

.profiles h3 {
  margin: 10px 0 0 0;
}

.profiles p {
  color: #ccc;
  font-size: clamp(20px, 1.3vw, 30px);
}

.scheduleContainer {
  margin: 30px 0 0 0;
  padding-right: 5.73vw;
}

.scheduleContainer h1 {
  color: $opium;
  font-family: "Seconda Round Black";
  font-size: clamp(20px, 2vw, 40px);
  margin-bottom: 2.5vh;
  @media (min-width: 2200px) {
    margin-bottom: 0vh;
  }
}

.scheduleContainer p {
  color: $opium;
  font-family: "Seconda Round Medium";
  font-size: clamp(18px, 1vw, 26px);
}
