@import "../../../core/theme/colors.scss";
@import "../../../core/theme/themes.scss";

.getInTouchHeadline {
  font-family: "Seconda Round Black";
  color: $turquoise;
  font-size: 6.5vw;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  text-align: center;
  padding-top: 10vh;
}

.contactUsForm {
  background-color: #ebebeb;
  box-shadow: #00000029;
  // display: flex;
  margin-right: 10%;
  margin-left: 10%;
  border-radius: 10px;
  border-width: 1px;
  padding-top: 20px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 20px;
  margin-top: 24px;
  box-shadow: 0px 6px 5px #00000029;
  margin-bottom: 0px;
}

.discordAndMail {
  font-family: "Seconda Round Black";
  color: #8f6f6f;
  font-size: 22px;
  display: flex;
  justify-content: center;
  font-weight: bold;
}

.discord {
  display: flex;
  width: 73px;
  align-self: center;
}

.discordContainer {
  display: flex;
  flex-direction: column;
}

.nmcpCommunity {
  margin-top: 50px;
  margin-right: 20px;
  width: 90px;
  height: auto;
  align-self: flex-end;
  display: flex;
}

.contactDetails {
  align-items: center;
  justify-content: center;
  display: flex;
}

.map {
  width: 100%;
  height: 50%;
  margin-bottom: -80px;
  margin-top: 73px;
}

.input {
  width: 100%;
  display: flex;
  background-color: #cfcfcf;
  border-radius: 10px;
  border-width: 1px;
  padding: 16px;
  padding-left: 20px;
  border: 0;
  font-size: 18px;
  font-family: "Seconda Round Medium";
  margin-bottom: -25px;
}

.input::placeholder {
  color: $tertiary;
}

.input:focus {
  outline: none !important;
  border-color: #8f6f6f;
  box-shadow: 0 0 10px #8f6f6f;
}

.messageInput {
  height: 22vh;
  border-radius: 10px;
}

.messageInput:focus {
  outline: none !important;
  border-color: #8f6f6f;
  box-shadow: 0 0 10px #8f6f6f;
}

.sendButton {
  margin-top: 40px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.contactButton {
  border: none;
  background-color: transparent;
}

.mapIframe {
  width: 100%;
  height: 80vh;
  margin-bottom: -15vh;
}
