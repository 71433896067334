@import '../../../core/theme/colors.scss';
@import '../../../core/theme/themes.scss';
@import 'react-circular-progressbar/dist/styles.css';

.teamMembersContainer {
  display: flex;
  width: 100%;
}
.teamMembersComponentContainer {
  width: 50%;
}
.teamMembersComponent {
  display: flex;
  margin-left: -10vw;
}
.teamMembersDescriptionContainer {
  width: 50%;
  margin-top: 64px;
  margin-left: -100px;
}
.teamMembersTitle {
  font-family: 'Seconda Round Black';
  font-size: 2.2vw;
  color: $opium;
  text-align: center;
}

.progressBarsContainer {
  width: 12vw;
  height: 12vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.progressBar {
  display: flex;
  font-family: 'Seconda Round Black';
  font-size: 1.4vw;
}

.teamMembersDescription {
  font-family: 'Seconda Round Black';
  color: $opium;
  font-size: 1.3vw;
  text-align: center;
}
.separator {
  height: 30px;
}
.progressBarsContainers {
  display: flex;
  justify-self: center;
  align-items: center;
  margin-top: 80px;
  justify-content: space-between;
}
.ourDedicatedTeamText {
  font-family: 'Seconda Round Black';
  color: $opium;
  font-size: 2vw;
  display: flex;
  justify-content: center;
  margin-top: 140px;
}

.teamMembersListContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0;
}

.container {
  width: 100%;
  // padding-top: 15vh;
  padding: 15vh 10vw 0 10vw;
}

.centeredCol {
  display: flex;
  justify-content: center;
  align-items: center;
}

.topContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 150px;
  padding: 0 10vw;
  align-items: center;
  @media (min-width: 2200px) {
    padding: 0 15vw;
  }
}

.title {
  font-size: clamp(18px,2.6vw,40px);
  color: $bazaar;
  font-family: 'Seconda Round Black';
}

.description {
  color: $bazaar;
  font-size: clamp(15px,0.96vw,24px);
  text-align: justify;
  font-family: 'Seconda Round Medium';
}

.mainPhoto {
  width: 30vw;
  height: 25vw;
  border-radius: 20px;
  margin-left: 100px;
  @media (min-width: 2200px) {
    width: 25vw;
    height: 20vw;
  }
}

.bottomContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}

.secondaryTitle {
  font-size: clamp(15px,1.6vw,30px);
  font-family: 'Seconda Round Black';
  color: $opium;
}

.textHeader {
  font-size: clamp(12px,0.96vw,25px);
  color: $opium;
  font-family: 'Seconda Round Bold';
  display: list-item;
  text-align: justify;
  margin-left: 15px;
}

.text {
  font-size: clamp(12px,0.96vw,20px);
  color: $opium;
  font-family: 'Seconda Round Medium';
}

.textContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  @media (min-width: 2200px) {
    width: 80%;
    row-gap: 30px;
  }
}
