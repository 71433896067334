@import "../../../core/theme/colors.scss";
@import "../../../core//theme/themes.scss";

.container {
  display: flex;
  margin-top: 14vw;
  flex-direction: column;
  padding: 0 15vw;
  @media (min-width: 2200px) {
    padding: 0 20vw;
    margin-top: 8vw;
  }
}

.topContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 150px;
  align-items: center;
}

.title {
  font-size: 2.6vw;
  color: $bazaar;
  font-family: "Seconda Round Black";
  @media (min-width: 2200px) {
    font-size: 2vw;
  }
}

.description {
  width: 100%;
  color: $bazaar;
  font-size: 0.96rem;
  text-align: justify;
  @media (min-width: 2200px) {
    font-size: 1.3rem;
  }
}

.mainPhoto {
  width: 25vw;
  height: 16.6vw;
  border-radius: 20px;
  margin-left: 100px;
}

.mainPhotoDesktopSmall {
  width: 80vw;
  border-radius: 20px;
  margin-bottom: 30px;
  margin-top: 10px;
}

.aboutImage {
  width: clamp(200px, 15vw, 400px);
  height: clamp(200px, 15vw, 400px);
  object-fit: cover;
  border-radius: 20px;
}

.aboutImageBackground {
  display: grid;
  grid-gap: 2vw;
  justify-content: space-around;
  background-repeat: no-repeat;
  width: 71.5vw;
  height: auto;
  align-self: center;
  background-size: contain;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  background-position: center;
  @media (min-width: 2200px) {
    width: 55vw;
  }
}

.colorOverlay {
  width: clamp(200px, 15vw, 400px);
  height: clamp(200px, 15vw, 400px);
  border-radius: 20px;
  position: absolute;
  opacity: 0;
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -ms-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
  font-size: 2vw;
  color: white;
  font-family: "Seconda Round Bold";
}

.aboutImageContainer:hover .colorOverlay {
  opacity: 1;
}

.discordAndMail {
  font-family: "Seconda Round Black";
  color: #8f6f6f;
  font-size: 22px;
  display: flex;
  justify-content: center;
  font-weight: bold;
}

.discord {
  display: flex;
  width: 85.6px;
  align-self: center;
  cursor: pointer;
  margin-top: 20px;
}

.discordContainer {
  display: flex;
  flex-direction: column;
}

.getInTouchHeadline {
  font-family: "Seconda Round Black";
  color: $turquoise;
  font-size: 28px;
  font-weight: bold;
  display: flex;
  justify-content: center;
}

.aboutDiscordText {
  font-size: 1.2rem;
  font-family: "Seconda Round Bold";
  text-align: center;
  color: #8f6f6f;
  margin: 100px 0;
  @media (min-width: 2200px) {
    font-size: 1.5rem;
    margin: 100px 250px;
    text-align: center;
  }
}
