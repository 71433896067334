@import "../../../core/theme/colors.scss";
@import "../../../core/theme/themes.scss";

.servicesNmcpLogo {
  width: 95%;
}

.container {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  padding-top: 15vh;
}

.headerTextContainer {
  font-family: "Seconda Round Bold";
  margin-top: 20px;
  margin-bottom: 2vw;
  color: $primary;
  font-size: 24px;
  font-weight: bold;
}
