@import "../../../core/theme/colors.scss";
@import "../../../core/theme/themes.scss";

.navbar {
  width: 80vw;
  height: 92px;
  background-color: $primaryTransparent;
  border-radius: 0px 0px 30px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: transform 0.5s ease, visibility 0s linear 0.3s;
}
.hidden {
  transform: translateY(-100%);
  visibility: hidden;
  transition: transform 0.3s ease, visibility 0s linear 0.3s;
}
.container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  z-index: 10;
}

.logo {
  height: auto;
  width: 80%;
}

.links {
  display: flex;
  width: 30%;
  justify-content: space-between;
  flex-direction: column;
  background-color: $secondary;
  position: absolute;
  top: 0;
  margin-left: 20px;
  padding-top: 80px;
  padding-bottom: 20px;
  border-radius: 0px 20px 20px 0px;
  animation: fadeIn 0.4s;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

.links > a {
  font-family: "Seconda Round Black";
  color: $opium;
  font-weight: bold;
  font-size: 20px;
  cursor: pointer;
  text-decoration: none;
  padding: 15px;
  padding-left: 20px;
}

.menuContainer {
  display: flex;
  width: 35px;
  height: 22px;
  justify-content: space-between;
  flex-direction: column;
  margin-left: 40px;
  z-index: 7;
  cursor: pointer;
  border: none;
}

.menuFirstLine,
.menuSecondLine,
.menuThirdLine {
  width: 35px;
  height: 4px;
  background-color: white;
  display: flex;
  border-radius: 3px;
}

.container:hover .menuSecondLine {
  animation: moveLeftSecond 0.4s forwards;
  @keyframes moveLeftSecond {
    0% {
      margin-left: 10px;
    }
    100% {
      margin-left: 0px;
    }
  }
}

.container:hover .menuThirdLine {
  animation: moveLeftThird 0.4s forwards;
  @keyframes moveLeftThird {
    0% {
      margin-left: 20px;
    }
    100% {
      margin-left: 0px;
    }
  }
}

.menuFirstLineEnd {
  width: 35px;
  height: 4px;
  background-color: $opium;
  display: flex;
  border-radius: 3px;
}

.menuSecondLineEnd {
  width: 35px;
  height: 4px;
  background-color: $opium;
  display: flex;
  border-radius: 3px;
  animation: moveRightSecond 0.4s forwards;
  @keyframes moveRightSecond {
    0% {
      margin-left: 0px;
    }
    100% {
      margin-left: 10px;
    }
  }
}

.menuThirdLineEnd {
  width: 35px;
  height: 4px;
  background-color: $opium;
  display: flex;
  border-radius: 3px;
  animation: moveRightThird 0.4s forwards;
  @keyframes moveRightThird {
    0% {
      margin-left: 0px;
    }
    100% {
      margin-left: 20px;
    }
  }
}
