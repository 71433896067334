@import "../../../core/theme/colors.scss";
@import "../../../core//theme/themes.scss";

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background: #ebebeb;
  padding: 2vw 2.5vw;
  width: 35vw;
  border-radius: 40px;
  @media (min-width: 2200px) {
    width: 30vw;
    padding: 1vw 1.5vw;
  }
}

.inputContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 12px 0;
}

.inputContainer ::-webkit-scrollbar {
  background-color: #dddcdc;
  border-radius: 3.5px;
  width: 10px;
}

.inputContainer ::-webkit-scrollbar-thumb {
  background-color: #eda751;
  border-radius: 3.5px;
}

.inputContainer input::placeholder,
textarea::placeholder {
  // font-size: 0.8vw !important;
  font-size: clamp(12px,0.8vw,20px) !important;
}

.inputContainer input,
textarea {
  background: #dddcdc;
  border-radius: 50px;
  border: none;
  padding: 11.2px 1.51vw;
  width: 100%;
  color: $burntCrimson;
  font-family: "Seconda Round Medium";
  font-size: clamp(0.8vw,0.8vw,25px);
  @media (min-width: 2200px) {
    padding: 11.2px 1vw;
  }
}

.inputContainer textarea {
  border-radius: 7px;
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
  resize: none;
  height: 11.31vw;
  font-size: 0.8vw;
  background: #dddcdc;
  @media (min-width: 2200px) {
    height: 9vw;
    padding: 10px 1vw;
  }
}

.inputContainer input:focus,
textarea:focus {
  outline: none;
}

.inputContainer input::placeholder,
textarea::placeholder {
  color: #8f6f6f;
  font-size: 1.04vw;
}

.inputContainer textarea::placeholder {
  color: #8f6f6f;
  font-size: 1.04vw;
}

.inputContainer .selectContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  margin: 12px 0;
}

.selectElement {
  margin: 12px 0;
  position: relative;
  display: flex;
  align-items: center;
  background: #dddcdc;
  border-radius: 50px;
  border: none;
  padding: 18px 1.51vw;
  width: 100%;
  color: $tertiary;
  font-family: "Seconda Round Medium";
  font-size: clamp(12px,0.8vw,20px);
  cursor: pointer;
  user-select: none;
  @media (min-width: 2200px) {
    padding: 18px 1.11vw;
  }
}

.isSelected {
  color: #5a1a1f;
}

.selectElement img {
  width: 0.96vw;
  position: absolute;
  right: 30px;
  pointer-events: none;
  user-select: none;
  transition: all 0.4s;
}

.selectElement.active img {
  transition: all 0.4s;
  transform: rotateX(180deg);
}

.optionsContainer {
  height: 0;
  width: 38vw;
  border-radius: 30px;
  position: absolute;
  margin-top: 10px;
  opacity: 0;
  overflow: hidden;
}

.optionsContainer.active {
  transition: all 0.4s;
  height: auto;
  background: #dddcdc;
  opacity: 1;
  z-index: 2;
  box-shadow: 2px 2px 10px rgb(0, 0, 0, 0.3);
}

.optionsContainer .optionElement {
  border: none;
  padding: 10px 1.51vw;
  width: 100%;
  color: $tertiary;
  font-family: "Seconda Round Medium";
  font-size: 0.8vw;
  cursor: pointer;
}

.optionsContainer .optionElement:hover {
  background: $orange;
  text-decoration: none;
}

.selectContainer label {
  cursor: pointer;
  user-select: none;
  width: 100%;
}

.selectContainer input {
  display: none;
}

.radioContainer {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-top: 10px;
  padding: 0 1.04vw;
  position: relative;
}

.radioContainer input {
  appearance: none;
  cursor: pointer;
  width: 0.96vw;
  height: 0.96vw;
  margin-right: 1.5625vw;
  border: 2px solid $tertiary;
  border-radius: 5px;
  @media (min-width: 2200px) {
    margin-right: 0.5vw;
  }
}

.radioContainer input:checked {
  background: url(../../../core/assets/profile/orange_checkbox_icon.svg);
  background-position: center;
  background-size: cover;
  border: none;
  width: 0.96vw;
  height: 0.96vw;
}

.radioContainer p {
  font-size: clamp(15px,0.8vw,20px);
  margin: 0;
  padding: 0;
  color: $tertiary;
  font-family: "Seconda Round Medium";
  font-style: italic;
  user-select: none;
}

.radioContainer span {
  color: $orange;
  text-decoration: underline;
  cursor: pointer;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 50px;
}

.errorInput {
  color: #ff3333 !important;
  &::placeholder {
    color: #ff3333 !important;
  }
}

.errorCheck {
  border-color: #ff3333 !important;
}

.modalBackDrop {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  width: 54%;
  height: 100%;
  z-index: 10;
  border-radius: 40px;
}

.modalContainer {
  background-color: #ebebeb;
  padding: 60px 50px 100px 50px;
  width: 50%;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  box-shadow: 0px 4px 10px 10px rgb(0, 0, 0, 0.3);
}

.modalTitle {
  font-size: 1.5vw;
  font-family: "Seconda Round Bold";
  color: $opium;
  margin-bottom: 30px;
}
