@import '../../../core/theme/colors.scss';
@import '../../../core/theme/themes.scss';
.portofolioContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-top: 5vh;
}
.mainTextContainer {
  display: flex;
  justify-content: center;
  text-align: center;
  background-size: 80%;
  background-position: center;
  background-repeat: no-repeat;
  margin-top: 6vw;
  width: 100%;
}
.containerProjectLogo {
  position: absolute;
  width: 20vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  align-self: center;
}
.projectLogo {
  background-size: cover;
  background-position: center;
  width: 70%;
  max-width: 250px;
  height: auto;
  margin-left: 15px;
}
.eclipse {
  width: 20vw;
  height: auto;
  position: relative;
  bottom: 10px;
  overflow: hidden;
}
.projectLogoChalkNote {
  background-size: cover;
  background-position: center;
  width: 10vw;
  height: auto;
  margin-left: 15px;
}
.textContainer {
  width: 70%;
  align-self: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  gap: 1vw;
  margin-top: 6vw;
}
.mainPhoto {
  width: 70%;
}
.title {
  font-family: 'Seconda Round Heavy';
  color: $tertiary;
  font-size: 38px;
  font-weight: 900;
}
.littleText {
  font-family: 'Seconda Round Medium';
  color: $tertiary;
  font-size: 25px;
  font-weight: 500;
}
.ourTeamBanner {
  width: 90%;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 100px;
}
.projectScreenContainer {
  height: 30vh;
  margin-bottom: 100px;
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease-in-out;
  max-width: 100%;
}
.projectScreenContainerActive {
  max-width: 120%;
}
.projectBoxContainer {
  width: 100%;
  height: 30vh;
  margin: 5px;
  overflow: hidden;
  margin-bottom: 10px;
  border: 3px solid #8f6f6f;
  border-radius: 10px;
}
.marginsTitle {
  margin-bottom: 6vw;
  margin-top: 6vw;
}
.marginsProjectScreen {
  margin-top: 6vw;
}

.projectName {
  font-family: 'Seconda Round Black';
  font-size: 2.5vw;
  position: relative;
  top: 1vw;
}

.topDetailContainer {
  flex-direction: row;
  display: flex;
  justify-content: center;
  width: 100%;
}

.detailsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-right: 10vw;
  margin-left: 10vw;
}

.topDetailType {
  font-family: 'Seconda Round Black';
  font-size: 2vw;
  color: #8f6f6f;
  margin: 5px 10px 0 0;
}

.topDetailText {
  font-family: 'Seconda Round Medium';
  font-size: 2vw;
  color: #8f6f6f;
  text-align: center;
}

.technologiesContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  row-gap: 30px;
}

.technologyContainer {
  display: flex;
  border: 3px solid #8f6f6f;
  border-radius: 10px;
  padding: 9px 25px 9px 25px;
  margin-left: 20px;
  margin-right: 20px;
}

.technologyText {
  font-size: 1.75vw;
  font-family: 'Seconda Round Medium';
  color: #8f6f6f;
}
