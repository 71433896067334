@import '../../../../../core/theme/colors.scss';
@import '../../../../../core/theme/themes.scss';

.profileBackground {
  padding: 50px 100px 110px 100px;
  border-radius: 10px;
  border-width: 1px;
  margin-top: 20px;
  height: 420px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  max-width: 450px;
}
.profilePicture {
  width: 145px;
  height: auto;
  border-width: 1;
  border-radius: 122px;
}

.profilePictureHover {
  width: 145px;
  height: auto;
  border-width: 1;
  border-radius: 122px;
}
.memberDetailsContainer {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.teamMembersListContainer {
  display: flex;
  justify-content: space-between;
  margin-right: 200px;
  margin-left: 200px;
  margin-top: 40px;
}
.name {
  margin-top: 10px;
  color: #8f6f6f;
  font-family: 'Seconda Round Black';
  font-size: 1.3vw;
  @media (min-width: 2400px) {
    font-size: 0.8vw;
  }
}
.role {
  color: #cccccc;
  margin-top: 5px;
  font-family: 'Seconda Round Black';
  font-size: 1.15vw;
  line-height: 30px;
  text-align: center;
  width: 100%;
  @media (min-width: 2200px) {
    font-size: clamp(20px, 0.6vw, 30px);
  }
}
.socialsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  width: 40%;
}
.seeProfileButtonContainer {
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  @media (min-width: 2200px) {
    margin-left: 1vw;
  }
}
.buttonLink {
  text-decoration: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  height: fit-content;
}
