@import "../../../core/theme/themes.scss";

.container {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;

}

.verticalLine {
  height: 51px;
  width: 5px;
  background-color: red;
  margin-top: 30px;
  margin-bottom: 30px;
  border-radius: 5px;
}

.card {
  border-radius: 30px;
  width: 100%;
  height: 500px;
  border-width: 5px;
  border-style: solid;
  display: flex;
  padding-top: 10px;
  padding-bottom: 30px;
  padding-left: 32px;
  padding-right: 32px;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.mainRow {
  display: flex;
  justify-content: center;
}

.mainImage {
  height: auto;
  width: 20%;
  align-self: center;
}

.textContainer { 
  padding-left: 60px;
  padding-right: 60px;
  text-align: center;
}

.title {
  font-family: "Seconda Round Black";
  align-self: center;
}
