@import '../../../core/theme/colors.scss';
@import '../../../core/theme/themes.scss';

.container {
  margin-top: 64px;
  padding-top: 64px;
  width: 100%;
  background-color: $primary;
  padding-left: 10%;
  padding-right: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.links {
  display: flex;
  width: 80%;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 64px;
}

.links > a {
  color: white;
  font-weight: bold;
  font-family: 'Seconda Round Black';
  font-size: 0.96vw;
  cursor: pointer;
  text-decoration: none;
  transition: 0.3s ease-out;

  &:hover {
    -webkit-text-stroke: 0.5px $white;
    color: transparent;
  }
}

.GDPR {
  color: $orange;
  text-decoration: underline;
  font-family: 'Seconda Round Bold';
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 20px;
}

.contactButton {
  margin-top: 24px;
  background-color: $secondary;
  width: 64px;
  height: 64px;
  font-size: x-large;
  border-radius: 100%;
}

.contactButtonInner {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 64px;
  width: 64px;
  margin-top: -8px;
  margin-left: -8px;
  border-radius: 100%;
  border: solid;
  color: white;
}

.contactButtonInner:hover {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: 0.2s;
  height: 95%;
  width: 95%;
  margin-top: 0px;
  margin-left: 0px;
}

.contactButtonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contactButtonContainer > p {
  color: white;
}

.row {
  display: flex;
  width: 80%;
  justify-content: space-between;
  margin-bottom: 64px;
}

.row img {
  width: 24px;
  height: 24px;
}

.container > p {
  font-family: 'Seconda Round Medium';
  font-size: 14.4px;
  color: $turquoise;
}
