@import "../../../../../core/theme/themes.scss";

.profileBackground {
	padding-top: 40px;
	padding-bottom: 40px;
	border-radius: 20px;
	width: 300px;
	background-size: cover;
	margin-top: 20px;
	display: flex;
	flex-direction: column;
}
.profilePicture {
	width: 150px;
	height: 150px;
	border-width: 1;
	border-radius: 122px;
	display: flex;
	align-self: center;
}
.memberDetailsContainer {
	justify-content: center;
	align-items: center;
	display: flex;
	flex-direction: column;
	margin-top: 10px;
}
.teamMembersListContainer {
	display: flex;
	justify-content: space-between;
	margin-right: 200px;
	margin-left: 200px;
	margin-top: 40px;
}
.name {
	font-family: "Seconda Round Black";
	color: #8f6f6f;
	font-size: 20px;
}
.role {
	font-family: "Seconda Round Black";
	color: #cccccc;
	font-size: 20px;
	text-align: center;
}
.mediaIcon {
	height: 20px;
}
.socialsContainer {
	justify-content: space-between;
	display: flex;
	align-items: center;
	margin-top: 5px;
	padding-left: 30px;
	padding-right: 30px;
}
.seeProfileButtonContainer {
	margin-top: 10px;
	justify-content: center;
	display: flex;
	align-items: center;
}
.buttonLink {
	text-decoration: none;
}
.profilePictureHover {
	width: 245px;
	height: auto;
	border-width: 1;
	border-radius: 122px;
}
