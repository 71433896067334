@import "../../../core/theme/colors.scss";
@import "../../../core//theme/themes.scss";

.discordContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 200px 0 0 0;
  padding: 0 5vw;
}

.discordContainer h1 {
  font-size: 3vw;
  color: var(--main-blue, #6aa5ac);
  font-family: "Seconda Round Medium";
}

.discordContainer p {
  font-size: 2vw;
  font-family: "Seconda Round Medium";
  color: $opium;
}

.discordContainer img {
  width: 7.5vw;
  cursor: pointer;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 0 100px 0;
  padding: 0 5vw;
  flex-direction: column;
  justify-content: center;
}

.staffContainer {
  margin: 0 1.04vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.profilesContainer {
  display: flex;
  justify-content: start;
  align-items: center;
}

.profiles {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 2vw;
}

.profiles img {
  width: 20vw;
}

.profiles h3 {
  color: $opium;
  font-family: "Seconda Round Black";
  font-size: 3vw;
}

.profiles h3 {
  margin: 10px 0 0 0;
}

.profiles p {
  color: #ccc;
}

.scheduleContainer {
  margin: 100px 0 0 0;
  padding-right: 5.73vw;
}

.scheduleContainer h1 {
  color: $opium;
  font-family: "Seconda Round Black";
  font-size: 6vw;
}

.scheduleContainer p {
  color: $opium;
  font-family: "Seconda Round Medium";
  font-size: 3.5vw;
  width: 70vw;
}
