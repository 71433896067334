.container {
	display: flex;
	width: 100%;
	height: 550px;
	align-items: center;
	justify-content: center;
	background-size: cover;
}

.pageLogo {
	height: auto;
	width: 35vw;
	// padding-bottom: 50px;
	padding-top: 100px;
}
