@import '../../../core/theme/colors.scss';
@import '../../../core/theme/themes.scss';
.portofolioContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}
.mainTextContainer {
  display: flex;

  justify-content: center;
  align-items: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin-top: 3vw;
  width: 100%;
}

.projectLogo {
  width: 50vw;
  max-width: 150px;
  height: auto;
  margin-left: 15px;
  position: relative;
  bottom: 5vh;
}
.eclipse {
  width: 60vw;
  height: auto;
  position: relative;
  bottom: 5vh;
}
.projectLogoChalkNote {
  background-size: cover;
  background-position: center;
  width: 35vw;
  height: auto;
  margin-left: 15px;
  position: relative;
  bottom: 5vh;
}
.textContainer {
  width: 70%;
  align-self: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  gap: 23px;
  margin-bottom: 50px;
}
.mainPhoto {
  width: 130%;
  margin-top: 10vh;
}
.title {
  font-family: 'Seconda Round Heavy';
  color: $tertiary;
  font-size: 5.5vw;
  font-weight: 900;
}
.littleText {
  font-family: 'Seconda Round Medium';
  color: $tertiary;
  font-size: 3.2vw;
  font-weight: 500;
}
.ourTeamBanner {
  width: 120%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: repeat-y;
  contain: content;
}
.projectDetails {
  margin-top: 3vh;
}
.projectScreenContainer {
  height: 30vh;
  margin-bottom: 100px;
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease-in-out;
  max-width: 100%;
}

.marginsTitle {
  margin-bottom: 30px;
  margin-top: 50px;
}
.containerProjectLogo {
  position: absolute;
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.projectName {
  font-family: 'Seconda Round Black';
  font-size: 17px;
  position: relative;
  top: 1vh;
  margin-left: 1vw;
}
.dogScout {
  color: $orange;
}
.bookTrade {
  color: rgb(70, 82, 128);
}

.topDetailContainer {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.detailsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.topDetailType {
  font-family: 'Seconda Round Black';
  font-size: 6vw;
  color: #8f6f6f;
  margin: 5px 8px 0 0;
}

.topDetailText {
  font-family: 'Seconda Round Medium';
  font-size: 6vw;
  color: #8f6f6f;
  text-align: center;
}

.technologiesContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  row-gap: 30px;
}

.technologyContainer {
  display: flex;
  border: 2px solid #8f6f6f;
  border-radius: 10px;
  padding: 5px 15px 5px 15px;
  margin-left: 20px;
  margin-right: 20px;
}

.technologyText {
  font-size: 5vw;
  font-family: 'Seconda Round Medium';
  color: #8f6f6f;
}
