
@import "../../../core/theme/themes.scss";

.container {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.verticalLine {
  height: 51px;
  width: 5px;
  background-color: red;
  margin-top: 30px;
  margin-bottom: 30px;
  border-radius: 5px;
}

.card {
  border-radius: 30px;
  width: 100%;
  border-width: 5px;
  border-style: solid;
  display: flex;
  padding-top: 10px;
  padding-bottom: 30px;
  flex-direction: column;
}

.mainRow {
  display: flex;
  justify-content: center;
}

.mainImage {
  height: auto;
  width: 20%;
  align-self: center;
}

.textContainer { 
  padding-right: 10px;
  padding-left: 10px;
  text-align: center;
}

.title {
  align-self: center;
  font-family:"Seconda Round Black";
}

.text {
  align-self: center;
  font-family:"Seconda Round Medium";
}
