@import "../../../../../core/theme/themes.scss";

.appDevPercentageContainer {
  justify-content: center;
  align-items: center;
  display: flex;
}
.progressBar {
  display: block;
}
.progressBarText {
  font-family: "Seconda Round Bold";
  justify-content: center;
  display: flex;
  font-size: 3vw;
  font-weight: bold;
}
.percentageText {
  font-family: "Seconda Round Bold";
  position: absolute;
  font-size: 14px;
  font-weight: bold;

}
