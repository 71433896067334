@import '../../../core/theme/themes.scss';
@import '../../../core/theme/colors.scss';

.container {
  width: 66%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.verticalLine {
  height: 50px;
  width: 5px;
  background-color: red;
  margin-top: 30px;
  margin-bottom: 15px;
  border-radius: 5px;
}

.card {
  border-radius: 30px;
  width: 90%;
  border-width: 5px;
  border-style: solid;
  display: flex;
  padding-top: 60px;
  padding-bottom: 60px;
  padding-left: 32px;
  padding-right: 32px;
  @media (min-width: 2200px) {
    width: 90%;
    max-width: 2300px;
  }
}

.servicesCardTitle {
  font-family: 'Seconda Round Black';
  font-size: clamp(18px,1.3vw,27px);
}

.servicesCardDescription {
  font-family: 'Seconda Round Black';
  font-size: clamp(15px,1vw,18px);
  margin-right: 20px;
  color: $opium;
}

.mainRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button {
  font-size: 1.1vw;
  margin-top: 10px;
  @media (min-width: 2200px) {
    margin-top: 50px;
  }
}

.mainImg {
  height: auto;
  width: 10vw;
  align-self: flex-start;
  @media (min-width: 2200px) {
    width: 7vw;
  }
}

.projectsContainer {
  display: flex;
  flex-direction: row;
  column-gap: 2vw;
  max-width: 75%;
  @media (min-width: 2200px) {
    width: 5vw;
  }
}

.projectsImg {
  transform: rotate(-45deg);
  width: 3vw;
  max-height: 50px;
  @media (min-width: 2200px) {
    width: 2vw;
  }
}

.projectImgContainer {
  width: 3vw;
  height: 3vw;
  border: 2px solid #8f6f6f;
  border-radius: 15px;
  cursor: pointer;
  transform: rotate(45deg);
  padding: 2.2vw;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: 2200px) {
    width: 1.5vw;
    height: 1.5vw;
    padding: 1.8vw;
    margin-top: 50px;
  }
  @media (min-width: 2600px) {
    width: 1.2vw;
    height: 1.2vw;
    padding: 1.5vw;
    margin-top: 50px;
  }
}
