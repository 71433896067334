@font-face {
  font-family: "Seconda Round Black";
  src: url("../fonts/SecondaRound-Black.ttf");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Seconda Round Bold";
  src: url("../fonts/SecondaRound-Bold.ttf");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Seconda Round Demi";
  src: url("../fonts/SecondaRound-Demi.ttf");
}

@font-face {
  font-family: "Seconda Round Medium";
  src: url("../fonts/SecondaRound-Medium.ttf");
}

@font-face {
  font-family: "Seconda Round Heavy";
  src: url("../fonts/SecondaRound-Heavy.ttf");
}
