.privacy {
    width: 80%;
    margin: auto;
    padding-bottom: 50px;
}
.title {
    font-weight: 600;
    text-align: center;
}
.titleMargin {
    margin-bottom: 4vw;
    margin-top: 100px;
}

.bold {
    font-weight: 600;
    margin-top: 15px;
    margin-bottom: 15px;
}
.paragraph {
    margin-top: 10px;
}
.section4 {
    margin-left: 4vw;
    margin-top: 10px;
}
.section5 {
    display: flex;
}

.strooongs {
    margin-right: 6px;
}