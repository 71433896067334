@import "../../../../../core/theme/colors.scss";
@import "../../../../../core/theme/themes.scss";

.profileBackground {
	padding-top: 20px;
	padding-right: 50px;
	padding-left: 50px;
	padding-bottom: 30px;
	border-radius: 20px;
	height: 350px;
	margin-top: 20px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	max-width: 260px;
}
.profilePicture {
	width: 120px;
	height: 120px;
	border-width: 1;
	border-radius: 122px;
	display: flex;
	align-self: center;
}
.memberDetailsContainer {
	justify-content: center;
	align-items: center;
	display: flex;
	flex-direction: column;
}
.teamMembersListContainer {
	display: flex;
	justify-content: space-between;
	margin-right: 200px;
	margin-left: 200px;
	margin-top: 40px;
}
.name {
	font-family: "Seconda Round Black";
	margin-top: 10px;
	color: $tertiary;
	font-size: 20px;
}
.role {
	font-family: "Seconda Round Black";
	color: $silver;
	margin-top: 5px;
	font-size: 20px;
	text-align: center;
}
.socialsContainer {
	justify-content: space-between;
	display: flex;
	align-items: center;
	margin-right: 10px;
	margin-left: 10px;
	margin-top: 24px;
}
.seeProfileButtonContainer {
	margin-top: 20px;
	justify-content: center;
	display: flex;
}
.buttonLink {
	text-decoration: none;
}
.profilePictureHover {
	width: 245px;
	height: auto;
	border-width: 1;
	border-radius: 122px;
}
