.imageInProgress {
  width: 650px;
  max-width: 80%;
  height: auto;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10%;
  min-height: 600px;
  margin-bottom: -80px;
}