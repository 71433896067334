@import "../../../core/theme/colors.scss";
@import "../../../core/theme/themes.scss";

.container {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 100px;
}


.backgroundImage {
  margin-bottom: 30px;
  height: 60vh;
  display: flex;
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;
  justify-content: flex-end;
  padding-left: 15vw;
  padding-bottom: 100px;
}

.title {
  font-family: "Seconda Round Bold";
  font-size: 45px;
  margin-bottom: 30px;
  color: $gray;
  font-weight: bold;
}

.text {
  font-family: "Seconda Round Bold";
  font-size: 16px;
  color: $gray;
  font-weight: bold;
}

.textContainer {
  width: 70%;
  align-self: center;
  margin-top: 50px;
}

.blogTitleContainer {
  display: flex;
  margin-bottom: 50px;
  font-weight: bold;
}

.blogTitleInput {
  width: 40vw;
  height: 40px;
  border-radius: 5px;
}

.blogImageContainer {
  display: flex;
  margin-bottom: 50px;
  font-weight: bold;
}

.selectedImage {
  width: 250px;
}

.datePicker {
  border: 2px solid black;
  border-radius: 5px
}

.contentContainer {
  margin-bottom: 50px;
}

.blogContent {
  min-height: 500px;
  border: 2px solid black;
  border-radius: 5px;
}

.blogContent iframe {
  pointer-events: none;
}

.saveButton {
  margin-bottom: 50px;
  justify-content: center;
  width: 100%;
  display: flex;
}

.quill-editor iframe {
  pointer-events: none;
}