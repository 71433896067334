@import "../../../core/theme/colors.scss";
.container {
  display: flex;
  background-color: #ebebeb;
  width: 68%;
  height: 28vw;
  flex-direction: row;
  border-radius: 20px;
  margin-top: 10vh;
  justify-content: center;
  align-items: center;
  margin-top: 10vh;
  @media (min-width: 2200px) {
    width: 60%;
    height: 18vw;
  }
}
.containerReversed {
  flex-direction: row-reverse;
  text-align: right;
}
.textContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 4vw;
  margin-right: 4vw;
  @media (min-width: 2200px) {
    justify-content: center;
  }
}
.images {
  width: 35vw;
  margin-left: 2vw;
  margin-right: 2vw;
  @media (min-width: 2200px) {
    width: 20vw;
  }
}
.title {
  font-size: clamp(18px,1.6vw,40px);
  color: $opium;
  font-family: "Seconda Round Heavy";
  font-style: normal;
  font-weight: 900;
  line-height: 70px;
}
.description {
  font-size: clamp(15px, 1.1vw, 30px);
  color: $opium;
  font-family: "Seconda Round Medium";
  margin-bottom: 3vh;
  font-style: normal;
  font-weight: 500;
  line-height: 1.6vw;
}
.marginButton {
  margin-bottom: 5vw;
  text-decoration: none;
  @media (min-width: 2200px) {
    margin-bottom: 1vw;
  }
}
.alignRight {
  display: flex;
  justify-content: right;
}
