@import "../../../core/theme/colors.scss";
@import "../../../core/theme/themes.scss";

.container {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.backgroundImage {
  margin-bottom: 30px;
  height: 60vh;
  display: flex;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  flex-direction: column;
  justify-content: flex-end;
  padding-left: 15vw;
  padding-bottom: 100px;
}

.category {
}

.title {
  font-family: "Seconda Round Bold";
  font-size: 45px;
  margin-bottom: 30px;
  color: $gray;
  font-weight: bold;
}

.text {
  font-family: "Seconda Round Bold";
  font-size: 20px;
  color: $gray;
  font-weight: bold;
}

.nameAndRole {
  font-family: "Seconda Round Bold";
  font-size: 30px;
  color: $gray;
  align-self: center;
  font-weight: bold;
  margin-left: 20px;
  display: flex;
  width: 100%;
}

.authorImage {
  width: 190px;
  height: auto;
}

.authorContainer {
  display: flex;
  margin-left: 15vw;
  align-items: center;
}

.authorFunction {
  font-family: "Seconda Round Bold";
  font-size: 20px;
  margin-top: -5px;
  color: $gray;
  align-self: center;
  font-weight: bold;
  margin-left: 20px;
  display: flex;
  width: 100%;
}

.personDiv {
  margin-top: 10px;
}

.textContainer {
  width: 70%;
  align-self: center;
  margin-top: 50px;
  text-align: justify;
}
