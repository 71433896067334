@import "../../../core/theme/colors.scss";
@import "../../../core/theme/themes.scss";

.container {
  width: 100%;
  contain: content;
}

.topMainButton {
  position: absolute;
  left: 60vw;
  top: 7vw;
}
.title {
  font-family: "Seconda Round Black";
  color: $opium;
  font-size: 25px;
  font-weight: bolder;
  margin-top: 20px;
  text-align: center;
}
.paddingLeftRight {
  padding-left: 10px;
  padding-right: 10px;
  contain: content;
  position: relative;
}
.description {
  font-family: "Seconda Round Black";
  color: $opium;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}
.graphicsTechImg {
  width: 61vw;
  height: auto;
}
.topHomepageBanner {
  width: 160%;
  margin-top: -45vw;
  margin-left: -30vw;
  transform: rotate(0deg);
}
.whyUsTitleAndText {
  width: 30%;
  margin-top: 10vw;
}
.whyUsTitleAndTextSecond {
  width: 35vw;
}
.whyUsTitleAndTextThird {
  width: 45%;
  margin-top: 30px;
}
.justifySpaceBetween {
  display: flex;
  justify-content: space-between;
}
.nmcpLogo {
  position: absolute;
  top: 15vw;
  left: 62vw;
  width: 35%;
}

.titleContainer {
  font-family: "Seconda Round Black", Arial, Helvetica, sans-serif;
  font-size: 3.5vw;
  color: $white;
}
.topContainer {
  position: absolute;
  top: 50vw;
  left: 12vw;
  width: 50%;
}

.letterA {
  width: 12vw;
  position: absolute;
  top: 12vw;
  left: 13vw;
}

.letterW {
  width: 12vw;
  position: absolute;
  top: 18vw;
  left: 35vw;
}

.letterM {
  width: 12vw;
  position: absolute;
  top: 35vw;
  left: 43vw;
}

.letterU {
  width: 12vw;
  position: absolute;
  top: 30vw;
  left: 20vw;
}

.aboutUsContainer {
  font-family: "Seconda Round Bold", Arial, Helvetica, sans-serif;
  font-size: 2vw;
  color: $white;
}
.whyUsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-top: -8vw;
}
.whyUsImage {
  width: 22vw;
  height: auto;
  contain: content;
  margin-top: -22vw;
}
.servicesContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.servicesImage {
  width: 22vw;
  height: auto;
  display: block;
  margin: auto;
  margin-bottom: -15vw;
}
.serviceTitleText {
  font-size: 25px;
  color: $white;
  font-weight: bold;
  margin-left: 20px;
  transition: 0.4s;
}
.serviceTitleU {
  font-size: 25px;
  color: $white;
  font-weight: bold;
  margin-left: 10px;
  transition: 0.4s;
}
.serviceCategoryText {
  font-size: 18px;
  color: $white;
  font-weight: bold;
  text-orientation: upright;
  writing-mode: vertical-rl;
  margin-left: 33px;
  transition: 0.4s;
}
.hoverTextContainer {
  display: flex;
  transition: 0.4s;
}

.servicesBannerContainer {
  width: 100%;
  height: 70vw;
  display: flex;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  align-content: center;
  flex-direction: column;
}

.servicesTitle {
  color: $opium;
  font-size: x-large;
  width: 80%;
  margin-top: -50px;
}
.servicesListContainer {
  align-self: center;
  margin-top: 5vw;
}
.servicesFirstImage {
  width: 18vw;
  height: auto;
  margin-left: 20px;
}
.servicesSecondImage {
  width: 18vw;
  height: auto;
  margin-left: 20px;
  margin-top: 10px;
}
.servicesListSecond {
  margin-left: 0px;
}
.diamondShape {
  width: 14vw;
  height: 14vw;
  background-color: $white;
  border: 2px solid $opium;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
  transform: rotate(45deg);
}

.firstHoneycombRow {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.secondHoneycombRow {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding-left: 22vw;
  padding-right: 22vw;
  margin-top: -3vw;
}
.thirdHoneycombRow {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: -3vw;
  padding-left: 11vw;
  padding-right: 11vw;
}
.lastHoneycombRow {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: -3vw;
}

.honeycombImage {
  height: 6vw;
  transform: rotate(-45deg);
}
.projectTitle {
  font-size: 25px;
  font-weight: bold;
  color: $opium;
}
.serviceTitle {
  font-size: 30px;
  font-weight: bold;
  color: $opium;
}
.titlesContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transform: rotate(-45deg);
}

.portofolioLogo {
  margin-top: -20px;
  margin-left: 30px;
  width: 310px;
  height: auto;
  display: block;
}

.portofolioBanner {
  width: 100%;
  height: auto;
  margin-top: -40px;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.honeycombContainer {
  width: 100vw;
  display: block;
  margin: auto;
  margin-top: -6vw;
}

.mainButton {
  justify-content: center;
  align-items: flex-start;
  display: flex;
  margin-top: -4vw;
  text-decoration: none;
}

.mainButtonPortofolio {
  justify-content: flex-end;
  align-items: flex-end;
  display: flex;
  margin-top: -10vw;
  margin-right: 10vw;
  text-decoration: none;
}
.whatToExpect {
  display: block;
  margin: auto;
}
.whatToExpectImage {
  width: 380px;
  height: auto;
  height: auto;
  margin: auto;
  display: block;
  margin-top: 50px;
}

.expectLineContainer {
  flex-direction: row;
  display: flex;
  width: 100%;
  margin-left: -20px;
}
.expectLine {
  margin: auto;
  display: block;
}
.upperLine {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 950px;
  width: 40%;
  padding-top: 60px;
  padding-bottom: 80px;
  margin-left: 80px;
}
.downLine {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 60px;
  padding-bottom: 80px;
}
.categoryText {
  text-align: center;
  width: 90%;
  margin-top: 30px;
  margin-bottom: 30px;
}
.firstCategoryTitle {
  font-family: "Seconda Round Black";
  font-size: 20px;
  color: #f6cf85;
  font-weight: bold;
}
.secondCategoryTitle {
  font-family: "Seconda Round Black";
  font-size: 20px;
  color: #ff91ab;
  font-weight: bold;
}
.thirdCategoryTitle {
  font-family: "Seconda Round Black";
  font-size: 20px;
  color: #72beb7;
  font-weight: bold;
}
.fourthCategoryTitle {
  font-family: "Seconda Round Black";
  font-size: 20px;
  color: #eda751;
  font-weight: bold;
}
.secondaryText {
  font-family: "Seconda Round Medium";
  font-size: 16px;
  color: #8f6f6f;
}
.emailToolsImage {
  width: 148px;
  height: 154px;
}
.executionToolsImage {
  width: 225px;
  height: 212px;
}
.settingsTools {
  width: 180px;
  height: 161px;
}
.brainstormingImage {
  width: 122px;
  height: 200px;
}
.ourTeamLogo {
  display: block;
  margin: auto;
  width: 316px;
  height: auto;
}
.ourTeamBanner {
  width: 100vw;
  height: auto;
  display: flex;
  contain: content;
  background-repeat: no-repeat, no-repeat;
  justify-content: center;
  align-items: center;
  margin-top: -8vw;
}
.personsContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  width: 90vw;
}
.personImage {
  width: 146px;
}
.personImageUneven {
  width: 146px;
}
.easeOut {
  opacity: 0;
  transition: 0.3s ease-out;
}
.easeIn {
  opacity: 1;
  transition: 0.3s ease-in;
}
.onHoverImage :last-child {
  display: none;
}
.onHoverImage:hover :last-child {
  cursor: pointer;
  display: block;
}
.onHoverImage:hover :first-child {
  cursor: pointer;
  display: none;
}
.nameText {
  font-family: "Seconda Round Black";
  font-size: 15px;
  font-weight: bold;
  color: $opium;
}
.functionText {
  font-family: "Seconda Round Black";
  font-size: 15px;
  font-weight: bold;
  color: $silver;
  margin-top: -5px;
}
.galleryLinesContainer {
  display: flex;
  justify-content: center;
  margin-top: 3vw;
  margin-left: -2vw;
  margin-bottom: 30px;
}
.galleryLinesContainer:hover {
  cursor: pointer;
}
.onHoverImage {
  display: flex;
  align-items: center;
}
.lineImage {
  margin-left: 2vw;
}
.imageContainer {
  width: 20%;
  display: flex;
  justify-content: center;
}
.settingsToolsImage {
  width: 8vw;
  height: auto;
}
