@import '../../../core/theme/colors.scss';
@import '../../../core/theme/themes.scss';

.contactBtnGroup {
  align-content: flex-start;
  margin-top: 24px;
  display: flex;
  width: 70px;
  height: 70px;
  transition: 0.3s;

  &:hover {
    transition: 0.3s;
    cursor: pointer;
    .contactButton {
      transition: 0.3s;
      margin-top: 0px;
      margin-left: 0px;
    }
    .contactButtonInner {
      transition: 0.3s;
      border: none;
    }
  }
}

.contactButton {
  z-index: 0;
  background-color: $secondary;
  width: 64px;
  height: 64px;
  border-radius: 100%;
  margin-top: 5px;
  margin-left: 5px;
    transition: 0.3s;
}

.contactButtonInner {
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 64px;
  width: 64px;
  border-radius: 100%;
  border: solid;
  color: $white;
  transition: 0.3s;
}

// .contactButtonInner:hover {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   position: relative;
//   height: 100%;
//   width: 100%;
//   margin-top: 0px;
//   margin-left: 0px;
//   transition: 0.3s;
//   cursor: pointer;
// }

.contactButtonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.contactButtonContainer > p {
  color: $white;
}

.row {
  display: grid;
  width: 100%;
  margin-bottom: 64px;
  grid-template-columns: 1fr 1fr 1fr;
}

.text {
  font-size: 1vw;
  text-align: center;
  font-family: 'Seconda Round Medium';
}
