.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
}

.squareContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border: 3px solid;
  border-radius: 20px;
  width: clamp(140px, 40vw, 149px);
  height: clamp(140px, 40vw, 149px);
  padding-top: 15px;
  padding-bottom: 5px;
  margin: 5px;
}

.squareContainer:nth-child(1) {
  order: 1;
  margin-left: 25%;
  margin-right: 25%;
}

.squareContainer:nth-child(2) {
  order: 2;
}

.squareContainer:nth-child(3) {
  order: 3;
}

.bottomContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.value {
  font-size: clamp(25px, 4vw, 27px);
  font-family: "Seconda Round Black";
  margin-bottom: -10px;
}

.title {
  font-size: clamp(10px, 2.5vw, 11.5px);
  font-family: "Seconda Round Black";
}

.icon {
  width: clamp(45px, 10vw, 48px);
}
