@import '../../../core/theme/colors.scss';
@import '../../../core/theme/themes.scss';

.container {
  display: flex;
  flex-direction: column;
  padding: 70px;
}

.image {
  border-radius: 10px;
  display: flex;
  width: 400px;
  height: 400px;
  background-size:cover;
  background-position: center;
  background-repeat: no-repeat;
}

.date {
  font-family: 'Seconda Round Medium';
  font-size: 25px;
  color: $gray;
}

.title {
  font-family: 'Seconda Round Bold';
  font-size: 25px;
  width: 400px;
  color: $gray;
  font-weight: bold;
}

.readMoreButton {
  font-family: 'Seconda Round Bold';
  color: $turquoise;
  font-size: 26px;
  width: 33%;
  border-bottom: 4px solid;
  cursor: pointer;
}

.button {
  display: flex;
  margin-top: 30px;
  margin-left: -30px;
}

.buttonLink {
  text-decoration: none;
}
