.container {
  width: 70%;
  display: flex;
  align-self: center;
  flex-direction: row;
  justify-content: space-between;
}
.image {
  width: 25vw;
  position: relative;
  opacity: 0.4;
}
.firstThreePhotos {
  display: flex;
  flex-direction: column;
  height: 50vh;
  align-items: center;
  justify-content: space-evenly;
}
.lastThreePhotos {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 50vh;

  justify-content: space-evenly;
}
.nessImage {
  width: 10vw;
}
.nestInnImage {
  width: 25vw;
  opacity: 0.4;
}
.nestInnImage:hover,
.nessImage:hover,
.image:hover,
.nessImage:hover,
.odeenImage:hover,
.izibacImage:hover,
.moonletImage:hover,
.pentalogImage:hover {
  opacity: 1;
}
.odeenImage {
  width: 25vw;
  position: relative;
  top: 10px;
  opacity: 0.3;
}
.izibacImage {
  width: 25vw;
  position: relative;

  opacity: 0.8;
}
.moonletImage {
  width: 25vw;
  position: relative;

  opacity: 0.7;
}
.pentalogImage {
  width: 25vw;
  position: relative;
  margin-top: 20px;
  opacity: 0.4;
}
