@import "../../../core/theme/colors.scss";
.container {
	display: flex;
	background-color: #ebebeb;
	width: 85%;

	flex-direction: column;
	border-radius: 20px;
	margin-top: 6vh;
	justify-content: center;
	align-items: center;
	padding-bottom: 5vh;
	padding-top: 5vh;
}
.textContainer {
	width: 80%;
	display: flex;
	text-align: center;
	flex-direction: column;
	margin-top: 5vh;
}
.imagesDesktop {
	width: 70vw;
	height: 19vh;
	margin-top: 3vh;
}
.imagesMobile {
	width: 75vw;
	height: 23vh;
	margin-top: 3vh;
}
.title {
	font-size: 18px;
	color: $opium;
	font-family: "Seconda Round Heavy";
	font-weight: 900;
}
.description {
	font-size: 14px;
	color: $opium;
	font-family: "Seconda Round Medium";
}
.marginButton {
	width: 100%;
	display: flex;
	justify-content: center;
	margin-top: 4vh;
	text-decoration: none;
}
