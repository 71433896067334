@import "../../../core/theme/colors.scss";

.container {
  width: 100%;
  padding-top: 20vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  @media (min-width: 2200px) {
    padding-top: 12vh;
  }
}
.mainTextContainer {
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // flex-direction: column;
  // text-align: center;
  // background-size: contain;
  // background-repeat: no-repeat;
  // margin-top: 3vw;
  // margin-left: 20vw;
  // margin-right: 20vw;
}
.mainTextTitle {
  font-family: "Seconda Round Black";
  font-size: 2.7vw;
  margin-bottom: 1vw;
  color: $white;
}
.mainTextText {
  font-family: "Seconda Round Black";
  font-size: 1.2vw;
  color: $white;
  text-align: center;
  margin-left: 1.8vw;
  margin-right: 1.8vw;
  margin-bottom: 4vw;
}

.ourPartners {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (min-width: 2200px) {
   margin-bottom: -5vw;
  }
}
.ourPartnersTitle {
  font-size: 4vw;
  color: $opium;
  font-weight: 700;
  font-family: "Seconda Round Medium";
}
.projectCardContainer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.removeLine {
  text-decoration: none;
}
.ourworkDetailsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 8vh;
  width: 60vw;
  text-align: center;
  @media (min-width: 2200px) {
    width: 30vw;
    margin-top: 5vh;
  }
}
.ourworkDetails {
  font-size: clamp(15px, 1vw, 25px);
  font-family: "Seconda Round Medium";
  color: $opium;
  margin-bottom: 25px;
}
.contactUs {
  font-size: clamp(15px, 1.1vw, 35px);
  font-family: "Seconda Round Black";
  text-decoration: underline;
  color: $opium;
  cursor: pointer;
}
