@import "../../../core/theme/colors.scss";
@import "../../../core/theme/themes.scss";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  padding-top: 15vh;
}
.mainTextContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 380px;
  height: 240px;
  background-repeat: no-repeat;
  text-align: center;
  margin-top: 2vh;
}

.mainTextTitle {
  font-family: "Seconda Round Black";
  font-size: 4.8vw;
  margin-top: 1vh;

  color: $white;
}
.text {
  width: 60%;
}
.mainTextText {
  font-family: "Seconda Round Black";
  font-size: 2.9vw;
  color: $white;
  text-align: center;
}

.ourPartners {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 3vh;
  margin-bottom: 5vh;
}
.ourPartnersTitle {
  font-size: 5vw;
  color: $opium;
  font-weight: 700;
  font-family: "Seconda Round Medium";
}
.projectCardContainer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.removeLine {
  text-decoration: none;
}
.ourworkDetailsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 8vh;
  width: 90vw;
  text-align: center;
}
.ourworkDetails {
  font-size: 4vw;
  font-family: "Seconda Round Medium";
  color: $opium;
  margin-bottom: 25px;
}
.contactUs {
  font-size: 5vw;
  font-family: "Seconda Round Black";
  text-decoration: underline;
  color: $opium;
  cursor: pointer;
}
