@import "../../../core/theme/colors.scss";
@import "../../../core//theme/themes.scss";

.container {
  padding: 60px 10.42vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 60px;
}

.container h1 {
  color: $opium;
  font-size: 6.5vw;
  font-family: "Seconda Round Black";
}

.container p {
  color: $opium;
  font-family: "Seconda Round Bold";
  font-size: 4vw;
  margin: 8px 0 50px 0;
  text-align: center;
}

.gridContainer {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  gap: 30px;
}

.gridContainer div {
  display: flex;
  justify-content: start;
  align-items: start;
  margin: 0 1.56vw -25px 0;
}

.gridContainer div:nth-last-child(-n + 3) {
  border-top: 1px solid $opium;
  padding-top: 20px;
}

.gridContainer p {
  color: $opium;
  font-family: "Seconda Round Medium";
  font-size: 4vw;
  margin-bottom: 10px;
}

.gridContainer span {
  color: #ffa229;
  font-family: "Seconda Round Bold";
  font-size: 4vw;
}
