@import "../../../core/theme/colors.scss";
@import "../../../core/theme/themes.scss";

.servicesNmcpLogo {
  width: 142vw;
}

.container {
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
  overflow-x: hidden;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 10vh;
}

.headerTextContainer {
  margin-top: 20px;
  margin-bottom: 2vw;
  color: $primary;
  font-family: "Seconda Round Bold";
  font-size: 6vw;
  font-weight: bold;
}
