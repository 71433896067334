@import "../../../core/theme/colors.scss";
@import "../../../core/theme/themes.scss";

.mainContainer {
  background-color: #fff4e3;
  width: 100%;
  height: auto;
  display: block;
  padding-bottom: 70px;
  margin-bottom: -70px;
  padding-top: 70px;
}

.eventsBar {
  margin-left: 50%;
  width: 63%;
  height: auto;
  transform: translate(-50%, 0);
}

.whiteRectangle {
  width: 452px;
  height: 452px;
  border: solid 1px #707070;
}

.detailsContainer {
  display: flex;
  flex-direction: row;
  margin-top: 50px;
  margin-left: 20%;
}

.textContainer {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.festivalText {
  color: #707070;
  font-size: 53px;
  font-family: "Seconda Round Black";
  margin-top: 50px;
}

.iconContainer {
  background-color: #f6cf85;
  width: 72px;
  height: 72px;
  border-radius: 50px;
}

.communityText {
  color: #f64817;
  font-size: 53px;
  font-family: "Seconda Round Black";
  line-height: 40px;
}

.locationContainer {
  display: flex;
  flex-direction: row;
  margin-top: 84px;
  align-items: center;
}

.locationIcon {
  border: solid 3px white;
  width: 72px;
  height: 72px;
  border-radius: 50px;
  padding: 15px;
  margin-left: -6px;
  margin-top: -6px;
  cursor: pointer;
}

.locationIcon:hover {
  margin-left: 0px;
  margin-top: 0px;
  transition: 0.3s;
}

.addressText {
  color: #eda751;
  font-size: 28px;
  font-family: "Seconda Round Medium";
  margin-left: 20px;
  text-decoration: underline;
  cursor: pointer;
}

.dateContainer {
  display: flex;
  flex-direction: row;
  margin-top: 11px;
  align-items: center;
}
.watchIcon {
  border: solid 3px white;
  width: 72px;
  height: 72px;
  border-radius: 50px;
  padding: 15px;
  margin-left: -6px;
  margin-top: -6px;
  cursor: pointer;
}

.watchIcon:hover {
  margin-left: 0px;
  margin-top: 0px;
  transition: 0.3s;
}

.dateText {
  color: #eda751;
  font-size: 28px;
  font-family: "Seconda Round Medium";
  margin-left: 20px;
}

.mozartText {
  margin-left: 20%;
  margin-right: 20%;
  color: #8f6f6f;
  font-family: "Seconda Round Medium";
  font-size: 22px;
  margin-top: 50px;
}

.buyingSection {
  height: 105px;
  background-color: white;
  margin-left: 19%;
  margin-right: 19%;
  border-radius: 10px;
  box-shadow: 0px 6px 6px 0px #00000029;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;
}

.buyingSectionModal {
  height: 80px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.cumparaBilete {
  font-size: 1.8vw;
  font-family: "Seconda Round Black";
  color: #8f6f6f;
  margin-left: 42px;
}

.cumparaBileteModal {
  font-size: 1.2vw;
  font-family: "Seconda Round Black";
  color: #8f6f6f;
  margin-left: 42px;
}

.priceText {
  font-size: 1.8vw;
  font-family: "Seconda Round Black";
  color: #8f6f6f;
  margin-right: 88px;
}

.priceTextModal {
  font-size: 1.2vw;
  font-family: "Seconda Round Black";
  color: #8f6f6f;
  margin-right: 5vw;
}

.ticketsNumberContainer {
  width: 9vw;
  height: 64px;
  color: #8f6f6f;
  border: solid 4px #8f6f6f;
  border-radius: 100px;
  align-items: center;
  justify-content: space-between;
  display: flex;
  padding-left: 18px;
  padding-right: 18px;
  margin-right: 64px;
}

.ticketsNumberContainerModal {
  width: 7vw;
  height: 45px;
  color: #8f6f6f;
  border: solid 4px #8f6f6f;
  border-radius: 100px;
  align-items: center;
  justify-content: space-between;
  display: flex;
  padding-left: 9px;
  padding-right: 9px;
  margin-right: 35px;
}

.operatorContainer {
  width: 25px;
  height: 25px;
  background-color: #ff91ab;
  display: flex;
  justify-content: center;
  font-weight: bold;
  border-radius: 20px;
  color: white;
  font-size: 25px;
  line-height: 20px;
  text-align: center;
  cursor: pointer;
}
.operatorContainerModal {
  width: 20px;
  height: 20px;
  background-color: #ff91ab;
  display: flex;
  justify-content: center;
  font-weight: bold;
  border-radius: 20px;
  color: white;
  font-size: 20px;
  line-height: 15px;
  text-align: center;
  cursor: pointer;
}

.buyButton {
  margin-right: 64px;
  align-self: center;
  justify-self: center;
  top: 50%;
  transform: translate(0, -30%);
}

.buyButtonModal {
  margin-right: 15px;
  transform: scale(0.75) translate(0, -30%);
  top: 50%;
}

.rightBuyingContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.numberTickets {
  color: #8f6f6f;
  font-size: 28px;
  font-family: "Seconda Round Demi";
}

.numberTicketsModal {
  color: #8f6f6f;
  font-size: 20px;
  font-family: "Seconda Round Demi";
}

.contactUsForm {
  background-color: #EBEBEB;
  box-shadow: #00000029;
  // display: flex;
  margin-right: 20%;
  margin-left: 20%;
  border-radius: 20px;
  border-width: 1px;
  padding-top: 20px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 42px;
  margin-top: 24px;
  box-shadow: 0px 6px 5px #00000029;
}

.discordAndMail {
  font-family: "Seconda Round Black";
  color: #8f6f6f;
  font-size: 35px;
  display: flex;
  justify-content: center;
  font-weight: bold;
}

.discord {
  display: flex;
  width: 107px;
  align-self: center;
  cursor: pointer;
}

.discordContainer {
  display: flex;
  flex-direction: column;
  margin-top: 167px;
}

.getInTouchHeadline {
  font-family: "Seconda Round Black";
  color: $turquoise;
  font-size: 50px;
  font-weight: bold;
  display: flex;
  justify-content: center;
}

.bottomContainer {
  flex-direction: row;
  display: flex;
}

.nmcpCommunity {
  margin-top: 25px;
}

.input {
  font-family: "Seconda Round Medium";
  font-size: 20px;
  width: 100%;
  display: flex;
  background-color: #CFCFCF;
  border-radius: 20px;
  border-width: 1px;
  padding: 10px;
  padding-left: 20px;
  border: 0;
}

.inputError {
  font-family: "Seconda Round Medium";
  font-size: 20px;
  width: 100%;
  display: flex;
  background-color: #CFCFCF;
  border-radius: 20px;
  border-width: 1px;
  padding: 10px;
  padding-left: 20px;
  border: 0;
  color: red;
  animation: shake 0.3s;
  @keyframes shake {
    from {
      margin-left: 15px;
      margin-left: 9px;
    }
    to {
      margin-right: 15px;
      margin-right: 9px;
    }
  }
  &::placeholder {
    color: red;
  }
}

.input:focus {
  outline: none !important;
  border-color: #8f6f6f;
  box-shadow: 0 0 10px #8f6f6f;
}

.messageInput {
  margin-top: 20px;
  height: 20vh;
  border-radius: 30px;
}

.messageInput:focus {
  outline: none !important;
  border-color: #8f6f6f;
  box-shadow: 0 0 10px #8f6f6f;
}

.sendButton {
  margin-top: 40px;
}

.contactButton {
  border: none;
  background-color: transparent;
  align-self: flex-start;
}

.detailsScreen {
  background-color: white;
  width: 50%;
  height: 400px;
  position: fixed;
  display: flex;
  z-index: 50;
  flex-direction: column;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -60%);
  box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.5);
  border-radius: 20px;
  padding: 30px;
  gap: 10px;
}

.ticketsText {
  font-family: "Seconda Round Medium";
  font-size: 20px;
  width: 100%;
  display: flex;
  background-color: #f6cf85;
  border-radius: 20px;
  border-width: 1px;
  padding: 10px;
  padding-left: 20px;
  border: 0;
}

.ticketsTextError {
  font-family: "Seconda Round Medium";
  font-size: 20px;
  width: 100%;
  display: flex;
  background-color: #f6cf85;
  border-radius: 20px;
  border-width: 1px;
  padding: 10px;
  padding-left: 20px;
  border: 0;
  color: red;
  animation: shake 0.3s;
  @keyframes shake {
    from {
      margin-left: 15px;
      margin-left: 9px;
    }
    to {
      margin-right: 15px;
      margin-right: 9px;
    }
  }
}

.programColumns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 20%;
  margin-right: 20%;
}

.programTitle {
  color: #8f6f6f;
  font-family: "Seconda Round Medium";
  font-size: 22px;
  margin-top: 50px;
  margin-left: 10px;
}

.programFirstText {
  color: #8f6f6f;
  font-family: "Seconda Round Medium";
  font-size: 22px;
  margin-top: 50px;
}

.programText {
  color: #8f6f6f;
  font-family: "Seconda Round Medium";
  font-size: 22px;
  margin-top: 10px;
}

//payment successful page

.container {
  background-color: white;
  width: 40%;
  height: 511px;
  border-radius: 10px;
  flex-direction: column;
  align-items: center;
  display: flex;
  padding: 3.5%;
  border: 1px solid #caa987;
  justify-content: space-between;
  margin-left: 50%;
  transform: translate(-50%, 0);
  box-shadow: 0px 3px 6px 0px #00000029;
}

.okButton {
  height: 69px;
  border: none;
  background-color: transparent;
}

.buttonsText {
  color: white;
  font-size: 14px;
  font-family: "Seconda Round, Regular";
}

.title {
  font-size: 2vw;
  color: #8f6f6f;
  font-family: "Seconda Round Black";
}

.details {
  font-size: 1.1vw;
  color: gray;
  font-family: "Seconda Round Regular";
  text-align: center;
  padding-left: 15%;
  padding-right: 15%;
}

.smiley {
  width: 52px;
  height: 52px;
  display: flex;
}
