@import "../../../core/theme/colors.scss";
@import "../../../core/theme/themes.scss";

// .mainButton {
//   width: 154px;
//   height: 49px;
//   border-radius: 50px;
//   align-content: center;
//   justify-content: center;
//   display: flex;
// }

// .innerButton {
//   font-family: "Seconda Round Bold";
//   align-self: center;
//   font-size: 17px;
//   font-weight: bold;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   position: absolute;
//   height: 49px;
//   width: 154px;
//   margin-top: -6px;
//   margin-left: -10px;
//   border-radius: 50px;
//   border: 3px solid;
//   color: $opium;

//   &:hover {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     position: relative;
//     height: 100%;
//     width: 100%;
//     margin-top: 0px;
//     margin-left: 0px;
//     transition: 0.3s;
//     cursor: pointer;
//     border: none;
//     color: $white;
//   }
// }

.mainButton {
	width: 100%;
	height: 2.5em;
	border-radius: 50px;
	margin-top: 5px;
	margin-left: 5px;
	position: absolute;
	z-index: 0;
}

.innerButton {
	z-index: 1;
	width: 100%;
	height: 2.5em;
	border-radius: 50px;
	align-content: center;
	justify-content: center;
	display: flex;
	border: 3px solid;
	color: $opium;
	text-decoration: none;
	& span {
		font-family: "Seconda Round Bold";
		font-size: 0.8em;
		font-weight: bold;
		align-self: center;
		text-decoration: none;
	}
}
.btnGroup {
	display: flex;
	position: relative;
	width: 18vw;
	height: 2.5em;
	min-width: 110px;
	&:hover {
		.mainButton {
			transition: 0.3s;
			margin-top: 0px;
			margin-left: 0px;
		}
		.innerButton {
			transition: 0.3s;
			color: $white;
			border: none;
		}
	}
}
