@import '../../../core/theme/colors.scss';
@import '../../../core/theme/themes.scss';

.navbar {
  width: 70vw;
  height: 92px;
  background-color: $primaryTransparent;
  border-radius: 0px 0px 50px 50px;
  display: flex;
  align-items: center;
  transition: transform 1s ease, visibility 0s linear 0.3s;
}

.hidden {
  transform: translateY(-100%);
  visibility: hidden;
  transition: transform 0.3s ease, visibility 0s linear 0.3s;
}

.container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  z-index: 10;
}

.logo {
  margin-left: 32px;
  height: 90%;
  width: 10vw;
  @media (min-width: 2200px) {
    width: 7vw;
  }
}

.links {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  margin-right: 20px;
}

.links > a {
  color: white;
  font-weight: bold;
  font-family: 'Seconda Round Black';
  font-size: 1.1vw;
  cursor: pointer;
  text-decoration: none;
  transition: 0.3s ease-out;
  @media (min-width: 2200px) {
    font-size: 0.9vw;
  }
  &:hover {
    -webkit-text-stroke: 0.5px $white;
    color: transparent;
  }
}
