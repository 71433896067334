@import "../../../core/theme/colors.scss";
@import "../../../core/theme/themes.scss";

.container {
  width: 100%;
}

.row {
  display: flex;
  justify-content: space-between;
  padding-top: 30px;
}

.percentageRow {
  display: flex;
  justify-content: space-between;
}

.profilePic {
  width: 330px;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-right: 30px;
}

.profilePicHover {
  width: 330px;
  margin-right: 30px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.upperContainer {
  flex-direction: row;
  display: flex;
  align-items: center;
  padding-left: 15%;
}

.percentageContainer {
  width: 100%;
}

.percentageLine {
  height: 6px;
  background-color: $orange;
  margin-bottom: 10px;
}

.percentageUnderline {
  width: 100%;
  height: 3px;
  background-color: $tertiary;
  margin-bottom: 20px;
}

.earlyYearsContainer {
  width: 100%;
}

.percentageTitle {
  font-family: "Seconda Round Demi";
  font-size: 25px;
  color: $tertiary;
  margin-bottom: 7px;
}

.name {
  font-family: "Seconda Round Black";
  font-size: 60px;
  color: $tertiary;
  font-weight: bold;
}

.role {
  font-family: "Seconda Round Black";
  font-size: 50px;
  color: $silver;
  font-weight: bold;
}

.title {
  flex-direction: row;
  display: flex;
  font-size: 27px;
  font-family: "Seconda Round Medium";
  color: $tertiary;
  font-weight: bold;
}

.subtitle {
  font-weight: normal;
  font-family: "Seconda Round Medium";
}

.descriptionTitle {
  font-family: "Seconda Round Black";
  font-size: 35px;
  font-weight: bold;
  color: #eda751;
}

.descriptionText {
  font-family: "Seconda Round Medium";
  font-size: 20px;
  color: $tertiary;
  display: flex;
  flex-direction: column;
}

.descriptionContainer {
  margin-right: 15%;
  margin-left: 15%;
}

.contactUsForm {
  background-color: #ebebeb;
  box-shadow: #00000029;
  // display: flex;
  border-radius: 20px;
  border-width: 1px;
  padding-top: 20px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 10px;
  margin-top: 24px;
  box-shadow: 0px 6px 5px #00000029;
}

.input {
  width: 100%;
  display: flex;
  background-color: #cfcfcf;
  border-radius: 50px;
  border-width: 1px;
  padding: 10px;
  padding-left: 20px;
  border: 0;
  font-family: "Seconda Round Medium";
}

.input::placeholder {
  color: #8f6f6f;
  font-family: "Seconda Round Medium";
  font-size: 20px;
}

.input:focus {
  outline: none !important;
  border-color: #8f6f6f;
  box-shadow: 0 0 10px #8f6f6f;
}

.messageInput {
  height: 20vh;
  border-radius: 50px;
  display: flex;
}

.bottomContainer {
  flex-direction: row;
  align-content: flex-start;
  justify-content: flex-start;
  display: flex;
}

.sendButton {
  margin-top: 10px;
  display: flex;
}

.skillList {
  font-family: "Seconda Round Medium";
  color: $tertiary;
  font-size: 22px;
  font-weight: 700;
  padding: 15px 0px 20px 20px;
}

//project component

.projectContainer {
  background-color: #ebebeb;
  display: flex;
  flex-direction: column;
  padding: 30px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  font-family: "Seconda Round Medium";
  color: $tertiary;
  margin-top: 20px;
}

.projectTitle {
  font-weight: 700;
  font-size: 30px;
}

.projectDescription {
  font-size: 20px;
  padding: 20px 0px;
}

.projectRoles {
  font-size: 25px;
  font-weight: 700;
  line-height: 30px;
}

.spacer {
  height: 20px;
}

.technologyText {
  color: #eda751;
}
