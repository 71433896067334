@import '../../../core/theme/colors.scss';
@import '../../../core//theme/themes.scss';

.descriptionAboutUsContainer {
  margin: 0;
}

.title {
  color: $opium;
  font-size: 1.7vw;
  font-family: 'Seconda Round Black';
  margin-top: 24px;
}
.paddingLeftRight {
  padding-left: 50px;
  padding-right: 50px;
  margin-bottom: 70px;
}
.description {
  color: $opium;
  font-family: 'Seconda Round Black';
  font-size: 1.2vw;
}
.graphicsTechImg {
  width: 100%;
  height: auto;
}
.banner {
  width: 110vw;
  object-fit: cover;
  margin-left: -4vw;
  padding-right: 7vw;
  margin-bottom: 5vw;
}
.titleContainer {
  font-size: 2vw;
  color: $white;
  font-family: 'Seconda Round Black', Arial, Helvetica, sans-serif;
  padding-top: 2rem;
}
.topContainer {
  position: absolute;
  top: 11vw;
  left: 20vw;
  width: 36vw;
}
.aboutUsContainer {
  font-size: 1.5vw;
  color: $white;
  font-family: 'Seconda Round Bold', Arial, Helvetica, sans-serif;
}
.whyUsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  margin-top: -200px;
}
.whyUsImage {
  width: 100%;
  height: auto;
  margin-bottom: 40px;
}

.letterA {
  height: 7vw;
  border-radius: 3.5vw;
  background-color: #6aa5ac;
  position: absolute;
  top: 10vw;
  left: 1vw;
  width: 7vw;
  transition: 0.4s;
}

.letterA :not(:first-child) {
  display: none;
  transition: 0.4s;
}

.imageA {
  z-index: 5;
  position: absolute;
}

.letterW {
  height: 7vw;
  border-radius: 3.5vw;
  background-color: #f6cf85;
  position: absolute;
  top: 24vw;
  left: 12vw;
  width: 7vw;
  transition: 0.4s;
}
.letterW :not(:first-child) {
  display: none;
  transition: 0.4s;
}

.letterU {
  height: 7vw;
  border-radius: 3.5vw;
  background-color: #eda751;
  position: absolute;
  top: 34vw;
  left: 31vw;
  width: 7vw;
  transition: 0.4s;
}
.letterU :not(:first-child) {
  display: none;
  transition: 0.4s;
}
.letterM {
  height: 7vw;
  border-radius: 3.5vw;
  background-color: #ff91ab;
  position: absolute;
  top: 40vw;
  left: 54vw;
  width: 7vw;
  transition: 0.4s;
}
.letterM :not(:first-child) {
  display: none;
  transition: 0.4s;
}

.letterA:hover {
  height: 55vh;
  display: flex;
  flex-direction: column;
  transition: 0.4s;
  cursor: pointer;
}
.letterA:hover :not(:first-child) {
  height: 55vh;
  transition: 0.4s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}

.letterW:hover {
  height: 55vh;
  transition: 0.4s;
  cursor: pointer;
}

.letterW:hover :not(:first-child) {
  height: 55vh;
  transition: 0.4s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}

.letterU:hover {
  height: 46vh;
  transition: 0.4s;
  cursor: pointer;
}
.letterU:hover :not(:first-child) {
  height: 46vh;
  transition: 0.4s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}

.letterM:hover {
  height: 49vh;
  transition: 0.4s;
  cursor: pointer;
}
.letterM:hover :not(:first-child) {
  height: 48vh;
  transition: 0.4s;
  display: flex;
  align-items: flex-start;
  cursor: pointer;
}

.serviceTitleText {
  font-family: 'Seconda Round Black';
  font-size: 2vw;
  color: $white;
  font-weight: bold;
  transition: 0.4s;
  padding-bottom: 10px;
}

.serviceTitleU {
  font-family: 'Seconda Round Black';
  font-size: 1.8vw;
  color: $white;
  font-weight: bold;
  transition: 0.4s;
  padding-bottom: 10px;
}
.serviceCategoryText {
  font-family: 'Seconda Round Black';
  font-size: 2.3vh;
  color: $white;
  font-weight: bold;
  text-orientation: upright;
  writing-mode: vertical-rl;
  transition: 0.4s;
  overflow: hidden;
}

.serviceCategoryTextU {
  font-family: 'Seconda Round Black';
  font-size: 3vh;
  color: $white;
  font-weight: bold;
  text-orientation: upright;
  writing-mode: vertical-rl;
  transition: 0.4s;
}

.serviceCategoryTextM {
  font-family: 'Seconda Round Black';
  font-size: 3.2vh;
  color: $white;
  font-weight: bold;
  text-orientation: upright;
  writing-mode: vertical-rl;
  transition: 0.4s;
}

.hoverTextContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  transition: 0.4s;
}

.servicesContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vw;
  padding: 10px 100px;
  @media (min-width: 2400px) {
    height: 35vw;
  }
}

.servicesBannerContainer {
  width: 90%;
  height: 80vw;
  height: auto;
  align-content: flex-start;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 5%;
  @media (min-width: 2400px) {
    // background-size: auto;
    width: 70%;
  }
}

.servicesText {
  align-self: flex-start;
  margin-top: 3vh;
}

.servicesAnimationContainer {
  display: flex;
  flex-direction: column;
  width: 8vw;
  height: 8vw;
  background-color: white;
  border: 4px solid #eda751;
  border-radius: '15px';
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding-left: 2%;
  padding-right: 2%;
  @media (max-width: 2400px) {
    width: 12vw;
    height: 12vw;
  }
}

.servicesAnimationContainer:hover :first-child {
  opacity: 0;
}

.servicesAnimationContainer:hover :nth-child(3) {
  opacity: 1;
}

.categoryImage {
  margin-bottom: min(55px, 3vw);
  transition: ease-in-out 0.1s;
  height: 6vw;
  @media (min-width: 2400px) {
    height: 4vw;
  }
}

.servicesAnimationContainer :nth-child(3) {
  opacity: 0;
  transition: 0.6s;
}

.servicesAnimationContainer:hover .textAnimation {
  padding-top: 0%;
  padding-bottom: 7%;
  display: block;
  transition: linear 0.2s;
  @media (min-width: 2400px) {
    padding-bottom: 5%;
  }
}

.servicesAnimationContainer .textAnimation {
  transition: linear 0.2s;
}

.textAnimation {
  display: flex;
  position: absolute;
  font-size: 0.5vw;
  font-family: 'Seconda Round Black';
  padding-top: 5%;
  align-self: center;
  @media (max-width: 2400px) {
    font-size: 0.9vw;
    padding-top: 7%;
  }
}

.servicesDescription {
  display: flex;
  align-self: center;
  position: absolute;
  text-align: center;
  width: 7vw;
  margin-top: 1vw;
  font-size: 0.5vw;
  color: black;
  font-family: 'Seconda Round Medium';
  @media (max-width: 2400px) {
    font-size: 0.8vw;
    width: 10vw;
  }
}

.servicesLogoImage {
  width: 10vw;
  @media (min-width: 2400px) {
    width: 9vw;
  }
}

.servicesTitle {
  color: $opium;
  font-size: 0.7vw;
  font-family: 'Seconda Round Demi';
  width: 100%;
  margin: 3vh 0px;
  @media (max-width: 2400px) {
    font-size: 1.1vw;
  }
}

.servicesListContainer {
  align-self: center;
  margin-left: 5vw;
  margin-top: 5vw;
  @media (min-width: 2400px) {
    margin-top: 3vw;
    margin-left: 1vw;
  }
}
.servicesFirstImage {
  width: 11vw;
}
.servicesSecondImage {
  margin-left: 6vw;
  width: 11vw;
}
.servicesListSecond {
  margin-left: 10vw;
  margin-top: 4vw;
  display: flex;
  flex-direction: row;
  @media (min-width: 2400px) {
    margin-top: 2vw;
    margin-left: 6vw;
  }
}
.diamondShape {
  width: 7vw;
  aspect-ratio: 1 / 1;
  background-color: $white;
  border: 3px solid $opium;
  border-radius: 2vw;
  align-items: center;
  justify-content: center;
  display: flex;
  transform: rotate(45deg);
  transition: 0.4s;
  @media (max-width: 2400px) {
    width: 9.5vw;
  }
}
.diamondShape:hover {
  border-radius: 0px;
  transition: 0.4s;
  cursor: pointer;
}
.diamondShape :not(:first-child) {
  display: none;
}
.diamondShape:hover :first-child {
  border-radius: 0px;
  display: none;
  transition: 0.4s;
  cursor: pointer;
}
.diamondShape:hover :not(:first-child) {
  border-radius: 0px;
  transition: 0.4s;
  display: flex;
  cursor: pointer;
}

.firstHoneycombRow {
  margin: 0 9vw;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.secondHoneycombRow {
  margin: -3vw 2vw 0 2vw;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.honeycombImage {
  height: 2.5vw;
  transform: rotate(-45deg);
}
.projectTitle {
  font-family: 'Seconda Round Black';
  font-size: 0.8vw;
  font-weight: bold;
  color: $opium;
  @media (max-width: 2400px) {
    font-size: 1.2vw;
  }
}
.serviceTitle {
  font-family: 'Seconda Round Black';
  font-size: 0.8vw;
  font-weight: bold;
  color: $opium;
  @media (max-width: 2400px) {
    font-size: 1.1vw;
  }
}
.titlesContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transform: rotate(-45deg);
}

.portofolioLogo {
  margin-bottom: -100px;
  margin-left: 42%;
  width: 16vw;
  @media (min-width: 2400px) {
    width: 13vw;
    margin-bottom: -200px;
  }
}

.portofolioBanner {
  width: 100%;
  height: 35vw;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  @media (min-width: 2400px) {
    height: 30vw;
    background-size: 70%;
  }
}

.honeycombContainer {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 5vw;
  @media (min-width: 2400px) {
    margin-bottom: 2vw;
  }
}

.mainButton {
  justify-content: center;
  align-items: flex-start;
  display: flex;
  margin-top: -350px;
}
.mainButtonPortofolio {
  justify-content: center;
  align-items: flex-start;
  display: flex;
  cursor: pointer;
  margin-top: -50px;
}

.whatToExpect {
  display: flex;
  justify-content: start;
  padding-left: 10%;
  margin-bottom: -90px;
}

.whatToExpectImage {
  width: 23vw;
  margin-left: 3vw;
  @media (min-width: 2400px) {
    width: 18vw;
  }
}

.expectLine {
  margin: auto;
  display: block;
  width: 90%;
}
.upperLine {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  padding-right: 8%;
  padding-left: 11.5%;
  margin-bottom: 30px;
}
.downLine {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-right: 11%;
  padding-left: 7%;
  margin-top: 30px;
}
.categoryText {
  text-align: center;
  width: 30%;
}
.imageContainer {
  width: 20%;
  display: flex;
  justify-content: center;
}
.firstCategoryTitle {
  font-size: clamp(15px, 0.96vw, 26px);
  color: #f6cf85;
  font-family: 'Seconda Round Black';
  font-weight: bold;
}
.secondCategoryTitle {
  font-size: clamp(15px, 0.96vw, 26px);
  color: #ff91ab;
  font-family: 'Seconda Round Black';
  font-weight: bold;
}
.thirdCategoryTitle {
  font-size: clamp(15px, 0.96vw, 26px);
  color: #72beb7;
  font-family: 'Seconda Round Black';
  font-weight: bold;
}
.fourthCategoryTitle {
  display: flex;
  justify-content: center;
  padding-right: 6.5vw;
  margin-left: -3.64vw;

  width: 33vw;
  font-size: clamp(15px, 0.96vw, 26px);
  color: #eda751;
  font-family: 'Seconda Round Black';
  font-weight: bold;
}
.secondaryText {
  text-align: center;
  font-size: clamp(15px, 0.96vw, 22px);
  color: #8f6f6f;
  font-family: 'Seconda Round Medium';
}
.emailToolsImage {
  width: 6vw;
  height: 7vw;
  @media (min-width: 2400px) {
    width: 4vw;
    height: 4.5vw;
  }
}
.executionToolsImage {
  width: 9vw;
  height: auto;
  @media (min-width: 2400px) {
    width: 5vw;
  }
}
.settingsToolsImage {
  width: 8vw;
  height: auto;
  @media (min-width: 2400px) {
    width: 6vw;
  }
}
.brainstormingImage {
  width: 5vw;
  height: auto;
  @media (min-width: 2400px) {
    width: 4vw;
  }
}
.ourTeamLogo {
  display: block;
  margin: auto;
  width: 23vw;
  padding-top: 7vw;
}
.ourTeamBanner {
  width: 100vw;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  justify-content: center;
}
.personsContainer {
  width: 85%;
  display: flex;
  justify-content: space-between;
  margin-top: -7vh;
}
.personImage {
  width: 13vw;
}
.personImageUneven {
  width: 13vw;
  margin-top: 6vh;
}
.easeOut {
  opacity: 0;
  transition: 0.3s ease-out;
}
.easeIn {
  opacity: 1;
  transition: 0.3s ease-in;
}
.onHoverImage :last-child {
  display: none;
}
.onHoverImage:hover :last-child {
  cursor: pointer;
  display: block;
}
.onHoverImage:hover :first-child {
  cursor: pointer;
  display: none;
}
.nameText {
  font-size: 1.5vw;
  font-family: 'Seconda Round Black';
  font-weight: bold;
  color: $opium;
}
.functionText {
  font-size: 1.5vw;
  font-family: 'Seconda Round Black';
  font-weight: bold;
  margin-top: -10px;
  color: $silver;
}
.galleryLinesContainer {
  display: flex;
  justify-content: center;
  padding: 2vh 0px 10vh 0px;
  margin-left: -1.1vw;
}
.galleryLinesContainer:hover {
  cursor: pointer;
}
.lineImage {
  margin-left: 1.1vw;
}
