.container {
  display: flex;
  flex-direction: column;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 150vw;
  padding-bottom: 50px;
}

.mainContainer,
.noArrowsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40vh;
  width: 82vw;
}

.noArrowsContainer {
  margin-top: 10vh;

  @media (max-width: 350px) {
    margin-top: 6vh;
  }
}

.imageContainer {
  margin: 0 2vw;
  height: 5vh;
  width: 5vw;
  user-select: none;
}

.imageContainer img {
  height: 100%;
  width: 100%;
}

.detailsContainer {
  padding-top: 2vh;
  height: 100%;
  flex: 1;
}

.disabled {
  display: none;
}

.detailsContainer {
  width: 60vw;
}

.reviewAuthor {
  font-size: clamp(15px, 6vw, 3rem);
  font-family: 'Seconda Round Black';
  color: #8f6f6f;
  text-shadow: 0px 4px 4px rgb(0, 0, 0, 0.25);
  margin-bottom: 1.5vh;
}

.reviewText {
  font-size: clamp(13px, 3vw, 2rem);
  font-family: 'Seconda Round Medium';
  color: #8f6f6f;
  text-shadow: 0px 4px 4px rgb(0, 0, 0, 0.25);
}
