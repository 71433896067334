@import "../../../core/theme/colors.scss";
@import "../../../core/theme/themes.scss";

.container {
  width: 100vw;
  -webkit-fill-available: width;
  overflow-x: hidden;
}
.title {
  font-family: "Seconda Round Black";
  color: $opium;
  font-size: 22px;
  font-weight: bolder;
  margin-top: 20px;
  text-align: center;
}
.paddingLeftRight {
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
}
.description {
  font-family: "Seconda Round Black";
  color: $opium;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}
.graphicsTechImg {
  width: 100%;
  height: auto;
}
.topHomepageBanner {
  width: 120%;
  margin-left: -4vw;
}

.nmcpLogo {
  position: absolute;
  top: 38vw;
  left: 47vw;
  width: 55vw;
}

.titleContainer {
  font-family: "Seconda Round Black", Arial, Helvetica, sans-serif;
  font-size: 5vw;
  color: $white;
}
.topContainer {
  position: absolute;
  top: 110vw;
  left: 40px;
  margin-right: 50px;
}

.letterA {
  width: 18vw;
  position: absolute;
  top: 32vw;
  left: 4vw;
}

.letterW {
  width: 18vw;
  position: absolute;
  top: 45vw;
  left: 32vw;
}

.letterM {
  width: 18vw;
  position: absolute;
  top: 80vw;
  left: 32vw;
}

.letterU {
  width: 18vw;
  position: absolute;
  top: 65vw;
  left: 4vw;
}

.aboutUsContainer {
  font-family: "Seconda Round Bold", Arial, Helvetica, sans-serif;
  font-size: 4.5vw;
  color: $white;
}
.whyUsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-top: -80px;
  z-index: 3;
}
.whyUsImage {
  width: 220px;
  height: auto;
}
.servicesContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.servicesImage {
  width: 210px;
  height: auto;
  display: block;
  margin: auto;
  margin-bottom: -10vw;
}
.serviceTitleText {
  font-size: 25px;
  color: $white;
  font-weight: bold;
  margin-left: 20px;
  transition: 0.4s;
}
.serviceTitleU {
  font-size: 25px;
  color: $white;
  font-weight: bold;
  margin-left: 10px;
  transition: 0.4s;
}
.serviceCategoryText {
  font-size: 18px;
  color: $white;
  font-weight: bold;
  text-orientation: upright;
  writing-mode: vertical-rl;
  margin-left: 33px;
  transition: 0.4s;
}
.hoverTextContainer {
  display: flex;
  transition: 0.4s;
}

.servicesBannerContainer {
  width: 160%;
  height: 108vw;
  align-content: flex-start;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  flex-direction: column;
}

.servicesTitle {
  color: $opium;
  font-size: x-large;
  width: 80%;
  margin-top: -50px;
}
.servicesListContainer {
  align-self: center;
  margin-top: 30px;
}
.servicesFirstImage {
  width: 35vw;
  height: auto;
  margin-left: 15px;
}
.servicesSecondImage {
  width: 35vw;
  height: auto;
  margin-left: 15px;
  margin-top: 10px;
}
.servicesListSecond {
  margin-left: 0px;
}
.diamondShape {
  width: 19vw;
  height: 19vw;
  background-color: $white;
  border: 2px solid $opium;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
  transform: rotate(45deg);
}

.firstHoneycombRow {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.secondHoneycombRow {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding-left: 60px;
  padding-right: 60px;
  margin-top: -15px;
}
.thirdHoneycombRow {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: -15px;
}
.lastHoneycombRow {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: -15px;
}

.honeycombImage {
  height: 8vw;
  transform: rotate(-45deg);
}
.projectTitle {
  font-size: 25px;
  font-weight: bold;
  color: $opium;
}
.serviceTitle {
  font-size: 30px;
  font-weight: bold;
  color: $opium;
}
.titlesContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transform: rotate(-45deg);
}

.portofolioLogo {
  margin-top: -20px;
  margin-left: 10px;
  width: 310px;
  height: auto;
  margin: auto;
  display: block;
}

.portofolioBanner {
  width: 100%;
  height: auto;
  margin-top: -40px;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.honeycombContainer {
  width: 100%;
  margin-top: -5vh;
}

.mainButton {
  justify-content: center;
  align-items: flex-start;
  display: flex;
  text-decoration: none;
}
.mainButtonPortofolio {
  justify-content: center;
  align-items: flex-start;
  display: flex;
  margin-top: 8vw;
  text-decoration: none;
}

.whatToExpect {
  display: block;
  margin: auto;
}
.whatToExpectImage {
  width: 320px;
  height: auto;
  height: auto;
  margin: auto;
  display: block;
}

.expectLineContainer {
  flex-direction: row;
  display: flex;
  width: 100%;
  margin-left: -20px;
}
.expectLine {
  margin: auto;
  display: block;
}
.upperLine {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  height: 950px;
  width: 40%;
  padding-top: 60px;
  padding-bottom: 80px;
}
.downLine {
  width: 35%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.categoryText {
  text-align: center;
  width: 100%;
}
.firstCategoryTitle {
  font-family: "Seconda Round Black";
  font-size: 16px;
  color: #f6cf85;
  font-weight: bold;
}
.secondCategoryTitle {
  font-family: "Seconda Round Black";
  font-size: 16px;
  color: #ff91ab;
  font-weight: bold;
}
.thirdCategoryTitle {
  font-family: "Seconda Round Black";
  font-size: 16px;
  color: #72beb7;
  font-weight: bold;
}
.fourthCategoryTitle {
  font-family: "Seconda Round Black";
  font-size: 16px;
  color: #eda751;
  font-weight: bold;
}
.secondaryText {
  font-family: "Seconda Round Medium";
  font-size: 14px;
  color: #8f6f6f;
}
.emailToolsImage {
  width: 70px;
  height: 70px;
}
.executionToolsImage {
  width: 96px;
  height: 86px;
}
.settingsTools {
  width: 106px;
  height: 100px;
}
.brainstormingImage {
  width: 62px;
  height: 101px;
}
.ourTeamLogo {
  display: block;
  margin: auto;
  width: 270px;
  height: auto;
}
.ourTeamBanner {
  width: 100vw;
  height: auto;
  display: flex;
  contain: content;
  background-repeat: no-repeat, no-repeat;
  justify-content: center;
  align-items: center;
  margin-top: -8vw;
}
.personsContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  width: 90vw;
}
.personImage {
  width: 146px;
}
.personImageUneven {
  width: 146px;
}
.easeOut {
  opacity: 0;
  transition: 0.3s ease-out;
}
.easeIn {
  opacity: 1;
  transition: 0.3s ease-in;
}
.onHoverImage :last-child {
  display: none;
}
.onHoverImage:hover :last-child {
  cursor: pointer;
  display: block;
}
.onHoverImage:hover :first-child {
  cursor: pointer;
  display: none;
}
.nameText {
  font-family: "Seconda Round Black";
  font-size: 15px;
  font-weight: bold;
  color: $opium;
}
.functionText {
  font-family: "Seconda Round Black";
  font-size: 15px;
  font-weight: bold;
  color: $silver;
  margin-top: -5px;
}
.galleryLinesContainer {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  margin-left: -20px;
}
.galleryLinesContainer:hover {
  cursor: pointer;
}
.onHoverImage {
  display: flex;
  justify-content: center;
  align-items: center;
}
.lineImage {
  margin-left: 20px;
}
