@import "../../../core/theme/colors.scss";

.container {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 10vh;
}

.blogContainer {
  display: flex;
  width: 100%;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.categories {
  font-family: "Seconda Round Black";
  display: flex;
  margin-right: 70px;
  margin-top: 60px;
  font-size: 35px;
  font-weight: bold;
  cursor: pointer;
}

.categoriesSelected {
  display: flex;
  margin-right: 70px;
  font-size: 35px;
  font-weight: bold;
  border-bottom: 10px red solid;
}

.blogSoonImage {
  margin-top: 50px;
  width: 300px;
}
