@import "../../../core/theme/colors.scss";
@import "../../../core/theme/themes.scss";

.container {
    display: flex;
    width: 100%;
    flex-direction: column;
  }

.backgroundImage {
  margin-bottom: 30px;
  height: 40vh;
  display: flex;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  flex-direction: column;
  justify-content: flex-end;
  padding-left: 15vw;
  padding-bottom: 20px;
}

.title {
  font-family: "Seconda Round Bold";
  font-size: 30px;
  margin-bottom: 30px;
  color: $gray;
  font-weight: bold;
}

.text {
  font-family: "Seconda Round Medium";
  font-size: 16px;
  color: $gray;
  font-weight: bold;
  text-align: justify;
}

.textContainer {
  width: 90%;
  align-self: center;
}

.text img {
  max-width: 80vw !important;
  
}

.text h3{
  font-size: 18px !important;
}

.nameAndRole {
  font-family: "Seconda Round Bold";
  font-size: 24px;
  color: $gray;
  align-self: center;
  font-weight: bold;
  margin-left: 20px;
  display: flex;
  width: 100%;
}

.authorImage {
  width: 120px;
  height: auto;
}

.authorContainer {
  display: flex;
  margin-left: 10vw;
  align-items: center;
}

.authorFunction {
  font-family: "Seconda Round Bold";
  font-size: 18px;
  margin-top: -5px;
  color: $gray;
  align-self: center;
  font-weight: bold;
  margin-left: 20px;
  display: flex;
  width: 100%;
}

.personDiv {
  margin-top: 10px;
}

.textContainer {
  width: 80%;
  align-self: center;
  margin-top: 30px;
  text-align: justify;
}
