.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.squareContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border: 5px solid;
  border-radius: 20px;
  width: clamp(160px, 15vw, 160px);
  height: clamp(160px, 15vw, 160px);
  padding-top: 25px;
  padding-bottom: 25px;
}

.squareContainer:nth-child(-n + 2) {
  margin-right: 5vw;
}

.bottomContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.value {
  font-size: clamp(25px, 3vw, 40px);
  font-family: 'Seconda Round Black';
  margin-bottom: -15px;
}

.title {
  font-size: clamp(15px, 1vw, 15px);
  font-family: 'Seconda Round Black';
}

.icon {
  width: clamp(60px, 5vw, 60px);
}
