@import "../../../core/theme/colors.scss";
@import "../../../core/theme/themes.scss";
@import "react-circular-progressbar/dist/styles.css";

.teamMembersContainer {
  display: flex;
  width: 100%;
}
.teamMembersComponentContainer {
  width: 50%;
}
.teamMembersComponent {
  display: flex;
}
.teamMembersDescriptionContainer {
  width: 50%;
  margin-top: 10px;
}

.teamMembersTitle {
  font-family: "Seconda Round Bold";
  font-size: 26px;
  color: $tertiary;
  text-align: center;
}
.teamMembersDescription {
  font-family: "Seconda Round Bold";
  color: $tertiary;
  font-size: 18px;
  text-align: center;
}
.separator {
  height: 20px;
}
.progressBarsContainers {
  display: flex;
  margin-top: 34px;
  flex-wrap: wrap;
}

.progressBarsContainer {
  width: 19vw;
  height: 19vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.progressBar {
  display: block;
  font-family: "Seconda Round Black";
  font-size: 1.4vw;
}

.ourDedicatedTeamText {
  font-family: "Seconda Round Bold";
  color: $tertiary;
  font-size: 26px;
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.teamMembersListContainer {
  // margin-top: 40px;
  // background-color: red;
  display: flex;
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
}

.container {
  width: 100%;
  padding-top: 15vh;
}

.centeredCol {
  display: flex;
  justify-content: center;
  align-items: center;
}

.topContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 150px;
  padding-left: 10vw;
  padding-right: 10vw;
  align-items: center;
}

.title {
  font-size: 3.33vw;
  color: $bazaar;
  font-family: "Seconda Round Black";
}

.description {
  color: $bazaar;
  font-size: 1.2rem;
  text-align: justify;
  font-family: "Seconda Round Medium";
  width: 50%;
}

.mainPhoto {
  width: 50%;
  height: fit-content;
  border-radius: 20px;
  margin-left: 20px;
}

.bottomContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}

.secondaryTitle {
  font-size: 3vw;
  font-family: "Seconda Round Black";
  color: $opium;
}

.textHeader {
  font-size: 2.5vw;
  color: $opium;
  font-family: "Seconda Round Bold";
  display: list-item;
  text-align: justify;
  margin-left: 15px;
}

.text {
  font-size: 2.5vw;
  color: $opium;
  font-family: "Seconda Round Medium";
}

.textContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.topTextContainer {
  display: flex;
  flex-direction: row;
}

.topTitleImageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
