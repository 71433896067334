@import "../../../core/theme/colors.scss";
.container {
	display: flex;
	background-color: #ebebeb;
	width: 85%;
	height: 80%;
	flex-direction: column;
	border-radius: 20px;
	margin-top: 6vh;
	justify-content: center;
	align-items: center;
	padding-top: 5vh;
	padding-bottom: 3vh;
}
.textContainer {
	width: 80%;
	display: flex;
	text-align: center;
	flex-direction: column;
	margin-top: 5vh;
}
.imagesDesktop {
	width: 65vw;
	height: 33vw;
	margin-top: 5vh;
}
.imagesMobile {
	width: 556px;
	height: 356px;
	margin-top: 3vh;
}
.title {
	font-size: 4vw;
	color: $opium;
	font-family: "Seconda Round Medium";
	font-weight: 900;
	margin-top: 2;
}
.description {
	font-size: 3vw;
	color: $opium;
	font-family: "Seconda Round Medium";
}
.marginButton {
	width: 100%;
	display: flex;
	justify-content: center;
	margin-top: 4vh;
	text-decoration: none;
}
