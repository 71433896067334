@import "../../../core/theme/colors.scss";
@import "../../../core//theme/themes.scss";

.container {
  display: flex;
  margin-top: 14vw;
  flex-direction: column;
}

.topContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 55px;
  padding-left: 10vw;
  padding-right: 10vw;
  align-items: center;
  margin-top: 50px;
}

.title {
  font-size: 6vw;
  color: $bazaar;
  font-family: "Seconda Round Black";
  margin-bottom: 30px;
}

.description {
  color: $bazaar;
  font-size: 3vw;
}

.mainPhoto {
  width: 80vw;
  border-radius: 20px;
  margin-bottom: 20px;
}

.aboutImageContainer {
  margin-bottom: 30px;
}

.aboutImage {
  width: clamp(150px, 25vw, 430px);
  height: clamp(150px, 25vw, 430px);
  object-fit: cover;
  object-position: 50% 95%;
  border-radius: 7px;
}

.aboutImageBackground {
  display: grid;
  background-repeat: no-repeat;
  width: 100vw;
  height: auto;
  align-self: center;
  background-size: contain;
  grid-template-columns: repeat(2, 1fr);
  justify-items: center;
  padding-left: 8vw;
  padding-right: 8vw;
  background-position: center;
}

.colorOverlay {
  width: clamp(150px, 25vw, 430px);
  height: clamp(150px, 25vw, 430px);
  border-radius: 7px;
  position: absolute;
  opacity: 0;
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -ms-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
  font-size: 4vw;
  color: white;
  font-family: "Seconda Round Bold";
}

.aboutImageContainer:hover .colorOverlay {
  opacity: 1;
}

.discordAndMail {
  font-family: "Seconda Round Black";
  color: #8f6f6f;
  font-size: 15px;
  display: flex;
  justify-content: center;
  font-weight: bold;
}

.discord {
  display: flex;
  width: 60px;
  align-self: center;
  cursor: pointer;
  margin-top: 20px;
}

.discordContainer {
  display: flex;
  flex-direction: column;
}

.getInTouchHeadline {
  font-family: "Seconda Round Black";
  color: $turquoise;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  text-align: center;
}

.aboutDiscordText {
  font-size: 1.1rem;
  font-family: "Seconda Round Medium";
  padding-left: 10vw;
  padding-right: 10vw;
  color: #8f6f6f;
  margin-top: 50px;
  margin-bottom: 50px;
}
