@import "../../../../../core/theme/themes.scss";

.appDevPercentageContainer {
  justify-content: center;
  align-items: center;
  display: flex;
}
.progressBar {
  margin-right: 20px;
}
.progressBarText {
  font-family: "Seconda Round Bold";
  justify-content: center;
  display: flex;
  font-size: 24px;
  font-weight: bold;
}
.percentageText {
  font-family: "Seconda Round Bold";
  position: absolute;
  font-size: 24px;
  font-weight: bold;
  margin-left: 5px;
}
