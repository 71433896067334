.sliderContainer {
  padding: 20px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.imageContainer {
  width: 30vw;
  height: 51vw;
  border-radius: 5px;
}

.imageContainerWeb {
  width: 60vw;
  height: 35vw;
  border-radius: 5px;
}

.arrows {
  width: 22px;
  height: 30px;
  cursor: pointer;
}

.image {
  width: 100%;
  height: 100%;
  border-radius: 7px;
}

.placeholdereDiv {
  width: 22px;
}

.disabled {
  opacity: 0;
  cursor: auto;
}
