.container {
	display: flex;
	width: 100%;
	height: 636px;
	align-items: center;
	justify-content: center;
	background-size: auto;
	background-repeat: repeat;
}

.pageLogo {
	height: auto;
	width: 25%;
	padding-bottom: 45px;
	padding-top: 150px;
}
