@import '../../../core/theme/colors.scss';
@import '../../../core/theme/themes.scss';
.servicesNmcpLogo {
  width: 65%;
  @media (min-width: 2200px) {
    width: 45%;
    margin-top: 100px;
  }
}

.container {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  padding-top: 10vh;
}

.headerTextContainer {
  margin-top: 64px;
  margin-bottom: -4vw;
  font-family: 'Seconda Round Bold';
  & p {
    font-family: 'Seconda Round Bold';
    color: $primary;
    font-size: 2vw;
    @media (min-width: 2200px) {
      font-size: 1.5vw;
    }
  }
  
}

.subheaderTextContainer {
  width: 25%;
  margin-top: -8vw;
  text-align: center;
  font-family: 'Seconda Round Black';
  color: $tertiary;
  font-size: 1vw;
  @media (min-width: 2200px) {
    font-size: 0.6vw;
    width: 15%;
    margin-top: -4vw;
  }
}
