.screensContainer {
  padding: 20px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.imageContainer {
  width: 15vw;
  height: 28vw;
}

.imageContainerWeb {
  width: 31vw;
  height: 18vw;
}

.arrows {
  width: 17px;
  height: 23px;
  cursor: pointer;
  user-select: none;
}

.image {
  width: 100%;
  height: 100%;
  border-radius: 7px;
}

.placeholderDiv {
  width: 17px;
}

.disabled {
  opacity: 0;
  cursor: auto;
}
