@import '../../../core/theme/colors.scss';
@import '../../../core/theme/themes.scss';
.portofolioContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  scroll-behavior: auto;
}
.mainTextContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
}
.containerProjectLogo {
  position: absolute;
  width: 20vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.projectLogo {
  background-size: cover;
  background-position: center;
  width: 23vw;
  height: auto;
  margin-left: 15px;
  position: relative;
}
.eclipse {
  width: 31vw;
  height: auto;
  position: relative;
}
.projectLogoChalkNote {
  background-size: cover;
  background-position: center;
  width: 70%;
  height: auto;
  position: relative;
  margin-left: 1vw;
}
.textContainer {
  width: 70%;
  align-self: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  gap: 23px;
  margin-top: 4vh;
}
.mainPhoto {
  width: 90%;
  margin-top: 7vh;
}
.title {
  font-family: 'Seconda Round Heavy';
  color: $tertiary;
  font-size: 25px;
  font-weight: 900;
}
.littleText {
  font-family: 'Seconda Round Medium';
  color: $tertiary;
  font-size: 20px;
  font-weight: 500;
}
.ourTeamBanner {
  max-width: 100%;
  height: auto;
  display: flex;
  contain: content;
  background-position: center;
  background-repeat: repeat-y;
  align-items: center;
  margin-top: -8vw;
}

.marginsTitle {
  margin-top: 6vh;
  margin-bottom: 150px;
}

.projectName {
  font-family: 'Seconda Round Black';
  font-size: 20px;
  position: relative;
  top: 1.5vw;
  margin-left: 1vw;
}

.topDetailContainer {
  flex-direction: row;
  display: flex;
  justify-content: center;
  width: 100%;
}

.detailsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 5vw;
  margin-left: 5vw;
  margin-top: 20px;
}

.topDetailType {
  font-family: 'Seconda Round Black';
  font-size: 3vw;
  color: #8f6f6f;
  margin-right: 10px;
  margin: 3px 10px 0 0;
}

.topDetailText {
  font-family: 'Seconda Round Medium';
  font-size: 3vw;
  color: #8f6f6f;
  text-align: center;
}

.technologiesContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  row-gap: 30px;
}

.technologyContainer {
  display: flex;
  border: 2px solid #8f6f6f;
  border-radius: 10px;
  padding: 9px 20px 9px 20px;
  margin-left: 20px;
  margin-right: 20px;
}

.technologyText {
  font-size: 3vw;
  font-family: 'Seconda Round Medium';
  color: #8f6f6f;
}
