@import "../../../core/theme/colors.scss";
@import "../../../core/theme/themes.scss";

.mainContainer {
  background-color: #fff4e3;
  width: 100%;
  height: auto;
  display: block;
  padding-bottom: 80px;
  margin-bottom: -80px;
  padding-top: 80px;
}

.eventsBar {
  width: 90%;
  height: auto;
  height: 92px;
  background-color: #78cfc0;
  border-radius: 0px 0px 30px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 34px;
  padding-right: 34px;
  align-self: center;
  justify-self: center;
  margin-left: 50%;
  transform: translate(-50%, 0);
}

.eventsText {
  color: white;
  font-size: 25px;
  font-family: "Seconda Round Black";
}

.logoNmcp {
  height: 70%;
  width: auto;
}

.whiteRectangle {
  width: 90%;
  height: 700px;
  border: solid 1px #707070;
  background-color: white;
}

.circleLinesContainer {
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  background-size: contain;
  padding: 20px;
  padding-bottom: 40px;
}

.detailsContainer {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.textContainer {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.titleContainer {
  align-self: flex-start;
  margin-left: 5%;
  margin-top: 40px;
}

.festivalText {
  color: #707070;
  font-size: 53px;
  font-family: "Seconda Round Black";
}

.iconContainer {
  background-color: #f6cf85;
  width: 78px;
  height: 78px;
  border-radius: 78px;
  margin-left: 5%;
}

.communityText {
  color: #f64817;
  font-size: 53px;
  font-family: "Seconda Round Black";
  line-height: 40px;
}

.locationContainer {
  display: flex;
  flex-direction: row;
  margin-top: 31px;
  align-items: center;
}

.locationIcon {
  border: solid 3px white;
  width: 78px;
  height: 78px;
  border-radius: 78px;
  padding: 15px;
  margin-left: -6px;
  margin-top: -6px;
  cursor: pointer;
}

.locationIcon:hover {
  margin-left: 0px;
  margin-top: 0px;
  transition: 0.3s;
}

.addressText {
  color: #eda751;
  font-size: 28px;
  font-family: "Seconda Round Medium";
  margin-left: 20px;
  text-decoration: underline;
  cursor: pointer;
}

.dateContainer {
  display: flex;
  flex-direction: row;
  margin-top: 12px;
  align-items: center;
}

.watchIcon {
  border: solid 3px white;
  width: 78px;
  height: 78px;
  border-radius: 78px;
  padding: 15px;
  margin-left: -6px;
  margin-top: -6px;
  cursor: pointer;
}

.watchIcon:hover {
  margin-left: 0px;
  margin-top: 0px;
  transition: 0.3s;
}

.dateText {
  color: #eda751;
  font-size: 28px;
  font-family: "Seconda Round Medium";
  margin-left: 20px;
}

.mozartText {
  margin-left: 5%;
  margin-right: 5%;
  color: #8f6f6f;
  font-family: "Seconda Round Medium";
  text-align: start;
  font-size: 22px;
  margin-top: 28px;
}

.buyingSection {
  margin-left: 5%;
  margin-right: 5%;
  height: 107px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 6px 6px 0px #00000029;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 17px;
}

.cumparaBilete {
  font-size: 35px;
  font-family: "Seconda Round Black";
  color: #8f6f6f;
  margin-left: 50%;
  transform: translate(-50%, 0);
  margin-top: 44px;
}

.priceText {
  font-size: 25px;
  font-family: "Seconda Round Black";
  color: #8f6f6f;
  margin-top: -15px;
}

.ticketsNumberContainer {
  width: 193px;
  height: 65px;
  color: #8f6f6f;
  border: solid 4px #8f6f6f;
  border-radius: 100px;
  align-items: center;
  justify-content: space-between;
  display: flex;
  padding-left: 18px;
  padding-right: 18px;
  margin-left: 40px;
}

.operatorContainer {
  width: 30px;
  height: 30px;
  background-color: #ff91ab;
  display: flex;
  justify-content: center;
  font-weight: bold;
  border-radius: 20px;
  color: white;
  font-size: 30px;
  line-height: 20px;
  text-align: center;
  cursor: pointer;
}

.buyButton {
  margin-right: 40px;
  cursor: pointer;
}

.rightBuyingContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  justify-content: space-between;
  padding-bottom: 30px;
  padding-top: 30px;
}

.numberTickets {
  color: #8f6f6f;
  font-size: 28px;
  font-family: "Seconda Round Demi";
}

.getInTouchHeadline {
  font-family: "Seconda Round Black";
  color: $turquoise;
  font-size: 6.5vw;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  text-align: center;
}

.contactUsForm {
  background-color: #EBEBEB;
  box-shadow: #00000029;
  // display: flex;
  margin-right: 10%;
  margin-left: 10%;
  border-radius: 10px;
  border-width: 1px;
  padding-top: 20px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 20px;
  margin-top: 24px;
  box-shadow: 0px 6px 5px #00000029;
}

.discordAndMail {
  font-family: "Seconda Round Black";
  color: #8f6f6f;
  font-size: 22px;
  display: flex;
  justify-content: center;
  font-weight: bold;
}

.discord {
  display: flex;
  width: 73px;
  align-self: center;
}

.discordContainer {
  display: flex;
  flex-direction: column;
  margin-top: 84px;
}

.nmcpCommunity {
  margin-top: 25px;
  width: 110px;
  height: auto;
  margin-right: 20px;
}

.input {
  width: 100%;
  display: flex;
  background-color: #CFCFCF;
  border-radius: 10px;
  border-width: 1px;
  padding: 20px;
  border: 0;
  font-size: 20px;
  font-family: "Seconda Round Medium";
}

.inputError {
  width: 100%;
  display: flex;
  background-color: #CFCFCF;
  border-radius: 10px;
  border-width: 1px;
  padding: 20px;
  border: 0;
  font-size: 20px;
  font-family: "Seconda Round Medium";
  color: red;
  animation: shake 0.3s;
  @keyframes shake {
    from {
      margin-left: 15px;
      margin-left: 9px;
    }
    to {
      margin-right: 15px;
      margin-right: 9px;
    }
  }
  &::placeholder {
    color: red;
  }
}

.input::placeholder {
  color: $tertiary;
}

.input:focus {
  outline: none !important;
  border-color: #8f6f6f;
  box-shadow: 0 0 10px #8f6f6f;
}

.messageInput {
  height: 40vh;
  border-radius: 10px;
}

.messageInput:focus {
  outline: none !important;
  border-color: #8f6f6f;
  box-shadow: 0 0 10px #8f6f6f;
}

.sendButton {
  margin-top: 40px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}
.contactButton {
  border: none;
  background-color: transparent;
}

.bottomContainer {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
}

.detailsScreen {
  background-color: white;
  width: 60%;
  height: auto;
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 60%;
  left: 50%;
  z-index: 50;
  transform: translate(-45%, -60%);
  box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.5);
  border-radius: 20px;
  padding: 30px;
  gap: 10px;
}

.ticketsText {
  font-family: "Seconda Round Medium";
  font-size: 20px;
  width: 100%;
  display: flex;
  background-color: #f6cf85;
  border-radius: 20px;
  border-width: 1px;
  padding: 10px;
  padding-left: 20px;
  border: 0;
}

.ticketsTextError {
  font-family: "Seconda Round Medium";
  font-size: 20px;
  width: 100%;
  display: flex;
  background-color: #f6cf85;
  border-radius: 20px;
  border-width: 1px;
  padding: 10px;
  padding-left: 20px;
  border: 0;
  color: red;
  animation: shake 0.3s;
  @keyframes shake {
    from {
      margin-left: 15px;
      margin-left: 9px;
    }
    to {
      margin-right: 15px;
      margin-right: 9px;
    }
  }
}

.programTitle {
  color: #8f6f6f;
  font-family: "Seconda Round Medium";
  font-size: 22px;
  margin-top: 28px;
  margin-left: 5%;
}

.programFirstText {
  color: #8f6f6f;
  font-family: "Seconda Round Medium";
  font-size: 22px;
  margin-top: 15px;
  margin-left: 5%;
}

.programText {
  color: #8f6f6f;
  font-family: "Seconda Round Medium";
  font-size: 22px;
  margin-top: 5px;
  margin-left: 5%;
}

.numberTicketsModal {
  color: #8f6f6f;
  font-size: 20px;
  font-family: "Seconda Round Demi";
}

.buyButtonModal {
  transform: scale(0.95);
  cursor: pointer;
}

.operatorContainerModal {
  width: 20px;
  height: 20px;
  background-color: #ff91ab;
  display: flex;
  justify-content: center;
  font-weight: bold;
  border-radius: 20px;
  color: white;
  font-size: 20px;
  line-height: 15px;
  text-align: center;
  cursor: pointer;
}

.ticketsNumberContainerModal {
  width: 12vw;
  height: 45px;
  color: #8f6f6f;
  border: solid 4px #8f6f6f;
  border-radius: 100px;
  align-items: center;
  justify-content: space-between;
  display: flex;
  padding-left: 9px;
  padding-right: 9px;
  margin-right: 35px;
  margin-left: 4vw;
}

.priceTextModal {
  font-size: 1.8vw;
  font-family: "Seconda Round Black";
  color: #8f6f6f;
  margin-left: 3vw;
}

.cumparaBileteModal {
  font-size: 3vw;
  font-family: "Seconda Round Black";
  color: #8f6f6f;
  align-self: center;
}

.buyingSectionModal {
  height: 80px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-top: 20px;
}

.rightBuyingContainerModal {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
  width: 100%;
}

//payment successful page
.container {
  background-color: white;
  width: 60%;
  height: 511px;
  border-radius: 10px;
  flex-direction: column;
  align-items: center;
  display: flex;
  padding: 3.5%;
  border: 1px solid #caa987;
  justify-content: space-between;
  margin-left: 50%;
  transform: translate(-50%, 0);
  box-shadow: 0px 3px 6px 0px #00000029;
}

.okButton {
  height: 69px;
  border: none;
  background-color: transparent;
}

.buttonsText {
  color: white;
  font-size: 14px;
  font-family: "Seconda Round, Regular";
}

.title {
  font-size: 3vw;
  color: #8f6f6f;
  font-family: "Seconda Round Black";
}

.details {
  font-size: 2.5vw;
  color: gray;
  font-family: "Seconda Round Regular";
  text-align: center;
  padding-left: 10%;
  padding-right: 10%;
}

.smiley {
  width: 52px;
  height: 52px;
  display: flex;
}
