$primary: #78cfc0;
$primaryTransparent: #78cfc0cc;
$secondary: #ffdc9c;
$projectGray: #ebebeb;
$black29op: #00000029;
$opium: #8f6f6f;
$doveGray: #707070;
$white: #ffffff;
$tertiary: #8f6f6f;
$lightGray: #cfcfcf;
$turquoise: #6aa5ac;
$silver: #cccccc;
$orange: #eda751;
$red: red;
$pink: #ff91ab;
$gray: #7e7e7e;
$bazaar: #8f6f6f;
$burntCrimson: #5a1a1f;
