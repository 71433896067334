.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.squareContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border: 3px solid;
  border-radius: 20px;
  width: clamp(175px, 15vw, 195px);
  height: clamp(175px, 15vw, 195px);
  padding-top: 15px;
  padding-bottom: 5px;
}

.squareContainer:nth-child(-n + 2) {
  margin-right: 5vw;
}

.bottomContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.value {
  font-size: clamp(30px, 4vw, 36px);
  font-family: "Seconda Round Black";
  margin-bottom: -10px;
}

.title {
  font-size: clamp(14px, 2vw, 15px);
  font-family: "Seconda Round Black";
}

.icon {
  width: clamp(50px, 10vw, 63px);
}
