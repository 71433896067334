@import "../../../core/theme/colors.scss";
@import "../../../core/theme/themes.scss";

.container {
  display: flex;
  flex-direction: column;
  padding: 5vw;
}

.image {
  border-radius: 10px;
  display: flex;
  width: 35vw;
  height: 35vw;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.date {
  font-family: "Seconda Round Medium";
  font-size: 12px;
  color: $gray;
}

.title { 
  font-family: "Seconda Round Bold";
  font-size: 16px;
  width:31vw;
  color: $gray;
  font-weight: bold;
}

.readMoreButton {
  font-family: "Seconda Round Bold";
    color: $turquoise;
    font-size: 15px;
    width: 28%;
    border-bottom: 4px $orange solid;
    cursor: pointer;
}

.button {
  display: flex;
  margin-top: 30px;
  margin-left: -30px;
}

.buttonLink {
  text-decoration: none;
}
