@import "../../../core/theme/themes.scss";
@import "../../../core/theme/colors.scss";

.mainButton {
	width: 155px;
	height: 50px;
	border-radius: 50px;
	margin-top: 4px;
	margin-left: 7px;
	position: absolute;
	z-index: 0;
}

.innerButton {
	z-index: 1;
	width: 155px;
	height: 50px;
	border-radius: 50px;
	align-content: center;
	justify-content: center;
	display: flex;
	border: 3px solid;
	color: $opium;
	cursor: pointer;

	& span {
		font-family: "Seconda Round Bold";
		font-size: 17px;
		font-weight: bold;
		align-self: center;
	}
}

.btnGroup {
	display: flex;
	width: 155px;
	height: 50px;

	&:hover {
		.mainButton {
			transition: 0.3s;
			margin-top: 0px;
			margin-left: 0px;
		}
		.innerButton {
			transition: 0.3s;
			color: $white;
			border: none;
		}
	}
}
