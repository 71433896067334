.sliderContainer {
  padding: 20px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: center;
}

.imageContainer {
  width: 50vw;
  height: 95vw;
  border-radius: 5px;
  margin: 0 10px;
}

.imageContainerWeb {
  width: 75vw;
  height: 53vw;
  border-radius: 5px;
  margin: 0 10px;
}

.arrows {
  width: 18px;
  height: 26px;
  cursor: pointer;
}

.image {
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

.placeholderDiv {
  width: 18px;
}

.disabled {
  opacity: 0;
  cursor: auto;
}
