.row > .col-3,
.col,
.col-6,
.col-4,
.col-8 {
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    text-align: center;
  }
}
