@import '../../../core/theme/colors.scss';
@import '../../../core//theme/themes.scss';

.container {
  padding: 10px 10.42vw;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  margin: -100px 1.9vw;
  @media (min-width: 2200px) {
    padding: 10px 14vw;
  }
}

.container h1 {
  color: $opium;
  font-size: clamp(15px,2vw,40px);
  font-family: 'Seconda Round Black';
}

.container p {
  color: $opium;
  font-family: 'Seconda Round Bold';
  font-size: clamp(12px,1.2vw,35px);
  margin: 8px 0 50px 0;
}

.gridContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 10px;
}

.gridContainer div {
  display: flex;
  justify-content: start;
  align-items: start;
  margin: 0 1.56vw -25px 0;
}

.gridContainer div:nth-child(-n + 3) {
  border-bottom: 1px solid $opium;
  margin-bottom: 20px;
}

.gridContainer p {
  color: $opium;
  font-family: 'Seconda Round Medium';
  font-size: clamp(12px,0.96vw,25px);
}

.gridContainer span {
  color: #ffa229;
}
