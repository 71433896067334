@import "../../../core/theme/colors.scss";
@import "../../../core/theme/themes.scss";

.getInTouchHeadline {
	font-family: "Seconda Round Black";
	color: $turquoise;
	font-size: 50px;
	font-weight: bold;
	display: flex;
	justify-content: center;
}

.contactUsForm {
	background-color: #ebebeb;
	box-shadow: #00000029;
	margin-right: 20%;
	margin-left: 20%;
	border-radius: 20px;
	border-width: 1px;
	padding-top: 20px;
	padding-left: 40px;
	padding-right: 40px;
	padding-bottom: 42px;
	margin-bottom: 150px;
	margin-top: 24px;
	box-shadow: 0px 6px 5px #00000029;
}

.discordAndMail {
	font-family: "Seconda Round Black";
	color: #8f6f6f;
	font-size: 35px;
	display: flex;
	justify-content: center;
	font-weight: bold;
}

.discord {
	display: flex;
	width: 107px;
	align-self: center;
	cursor: pointer;
}

.discordContainer {
	display: flex;
	flex-direction: column;
}

.bottomContainer {
	flex-direction: row;
	display: flex;
}

.nmcpCommunity {
	width: 144px;
	height: 68px;
	margin-top: 20px;
	margin-right: 20px;
}

.input {
	font-family: "Seconda Round Medium";
	font-size: 20px;
	width: 100%;
	display: flex;
	background-color: #cfcfcf;
	border-radius: 20px;
	border-width: 1px;
	padding: 10px;
	padding-left: 20px;
	border: 0;
}

.input:focus {
	outline: none !important;
	border-color: #8f6f6f;
	box-shadow: 0 0 10px #8f6f6f;
}

.messageInput {
	margin-top: -20px;
	height: 20vh;
	border-radius: 30px;
}

.messageInput:focus {
	outline: none !important;
	border-color: #8f6f6f;
	box-shadow: 0 0 10px #8f6f6f;
}

.sendButton {
	align-items: start !important;
	margin-top: -40px;
	margin-left: -15px;
}

.contactButton {
	border: none;
	background-color: transparent;
}
.mapIframe {
	width: 100%;
	height: 70vh;
	margin-bottom: -70px;
}
