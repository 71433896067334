@import "../../../core/theme/colors.scss";
@import "../../../core/theme/themes.scss";

.container {
  margin-top: 64px;
  padding-top: 20px;
  width: 100%;
  background-color: $primary;
  padding-left: 10%;
  padding-right: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contactBtnGroup {
  margin-top: 15px;
  align-content: flex-start;
  display: flex;
  width: 55px;
  height: 55px;

  &:hover {
    .contactButton {
      transition: 0.3s;
      margin-top: 0px;
      margin-left: 0px;
    }
    .contactButtonInner {
      transition: 0.3s;
      border: none;
    }
  }
}

.contactButton {
  z-index: 0;
  background-color: $secondary;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  margin-top: 4px;
  margin-left: 4px;
}

.contactButtonInner {
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 50px;
  width: 50px;
  border-radius: 100%;
  border: solid;
  color: $white;
}

.contactButtonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.contactButtonContainer > p {
  font-family: "Seconda Round Medium";
  color: white;
  margin-bottom: 0px;
}

.buttonInnerImage {
  width: 20px;
  height: 20px;
}

.column {
  display: flex;
  width: 80%;
  justify-content: space-between;
  margin-bottom: 30px;
  flex-direction: column;
}

.row {
  display: flex;
  width: 80%;
  justify-content: space-between;
  margin-bottom: 30px;
}

.copyrightText {
  color: $turquoise;
  font-family: "Seconda Round Medium";
  font-size: 12px;
}

.logo {
  width: 163px;
  height: auto;
}

.GDPR {
  color: $orange;
  text-decoration: underline;
  font-family: "Seconda Round Bold";
  cursor: pointer;
  font-size: 18px;
  margin-bottom: 20px;
}

.row img {
  width: 25px;
  height: 25px;
}
