@import "../../../core/theme/colors.scss";

.scrollTopButtonContainer {
  position: -webkit-sticky;
  position: fixed;
  bottom: 3vh;
  margin-right: 20px;
  border-radius: 40px;
  z-index: 99;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  align-self: flex-end;
  justify-self: flex-end;
  right: 0;
}

.topBtnGroup {
  margin-top: 24px;
  display: flex;
  align-items: flex-end;
  align-self: flex-end;
  width: 60px;
  height: 60px;
  cursor: pointer;
  &:hover {
    cursor: pointer;
    .goTopButton {
      transition: 0.3s;
      margin-top: 0px;
      margin-left: 0px;
    }
    .upCircleButton {
      transition: 0.3s;
      border: none;
    }
    .goTopImg {
      background-image: url("../../../core/assets/upButtonArrowWhite.svg");
    }
  }
}

.goTopButton {
  z-index: 0;
  background-color: $pink;
  width: 55px;
  height: 55px;
  border-radius: 100%;
  margin-top: 5px;
  margin-left: 5px;
}

.upCircleButton {
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 55px;
  width: 55px;
  border-radius: 100%;
  border: solid;
  color: $opium;
}

.hideThis {
  display: none;
}

.goTopImg {
  background-image: url("../../../core/assets/upButtonArrow.svg");
  display: inline-block;
  width: 25px;
  height: 25px;
  background-size: cover;
}
